import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import Home from "./Components/Home";
import { BrowserRouter } from "react-router-dom";
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Components/Header";
import Home_TEMP from "./Components/Home_TEMP";
import Reports from "./Components/Reports";

const App = () => {
  return (
    <>
    <BrowserRouter>
      <Header/>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/reports" exact component={Reports} />
        <Route path="/t" exact component={Home_TEMP} />
        {/* <AdminRoute exact = {true} path = "/dashboard" component = {Dashboard} />
             */}
      </Switch>

      </BrowserRouter>
    </>
  );
};

export default App;
