import React,{useState} from "react";
import {Menu, Dropdown, Row, Col } from "antd";
import {
  BarChartOutlined,
  ArrowRightOutlined,
  LineChartOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "../Assets/css/home_TEMP.scss";
import { Line, Bar } from "react-chartjs-2";
import graphIcon from "../Assets/graph-icon.png";

const Home_TEMP = () => {

  const [chartType, setchartType] = useState(true);
  const [downloadModal, setdownloadModal] = useState(false);
  const [ShowChart, setShowChart] = useState(false);
  

  const handle = (params) => {
    console.log("object");
  };

  const { SubMenu } = Menu;

  const menu = (
    <Menu>
      <SubMenu title="Weekly">
        <Menu.Item>12 Weeks</Menu.Item>
        <Menu.Item>26 Weeks</Menu.Item>
        <Menu.Item>53 Weeks</Menu.Item>
      </SubMenu>
      <SubMenu title="Monthly">
        <Menu.Item>2 Months</Menu.Item>
        <Menu.Item>4 Months</Menu.Item>
      </SubMenu>
    </Menu>
  );
  const menu2 = (
    <Menu>
      <SubMenu title="New Listing">
        <Menu.Item>Number of Listing</Menu.Item>
        <Menu.Item>Listing Volume</Menu.Item>
        <Menu.Item>Average List Price</Menu.Item>
        <Menu.Item>Medium List Price</Menu.Item>
      </SubMenu>
      <SubMenu title="Contract Signed">
        <Menu.Item>3rd menu item</Menu.Item>
        <Menu.Item>4th menu item</Menu.Item>
      </SubMenu>
      <SubMenu title="Sold Listing">
        <Menu.Item>3rd menu item</Menu.Item>
        <Menu.Item>4th menu item</Menu.Item>
      </SubMenu>
    </Menu>
  );

  const chartBar = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: chartType === false && "rgba(0,0,0,1)",
        borderWidth: chartType === false && 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };

  const chartLine = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: chartType === false && "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };

  return (
    <>
    {/* <div className="container"> */}
        {/* <Row gutter={30} className="" style={{margin:'30px 70px'}}>
            <Col lg={5} xs={23}  className="bgclr m-2 filter-col">hii</Col>
            <Col lg={18} xs={23}  className="bg-dark m-2 graph-col">hii</Col>
        </Row> */}
        <div className="container d-lg-flex d-sm-inline">
          <div className="border border-danger filter-col">
          <div className="collapse-wrapper">
                <div
                  className="filter-header"
                  style={{ backgroundColor: "#0E5D72" }}
                >
                  Filter By
                </div>
                {/* <div className="p-3" style={{ color: "black" }}>
                  <p className="m-0 pl-2 ">Total Market Trends</p>
                </div> */}

                <Menu style={{ width: "auto" }} mode="inline">
                  <Menu.Item key="12">Total Market Trends</Menu.Item>
                  <SubMenu key="sub1" title="Market Movement By Location">
                    <Menu.Item className="side-item" onClick={()=>{console.log("object")}} key="1">
                      <input
                        type="radio"
                        className="mx-2 my-0"
                        name="rdo1"
                        onChange={() => setShowChart(true)}
                      />4    
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="2">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>East Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="3">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Montuak</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="4">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sag Harbor</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="5">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sagaponack</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="6">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>South Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="7">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Market Movement By Price Point">
                    <Menu.Item className="side-item" key="8">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Amangansett</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="9">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="10">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>East Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="11">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Montuak</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="12">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sagaponack</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="13">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="14">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="sub3" title="Reports (Coming Soon)">
                  Reports (Coming Soon)
                    {/* <Menu.Item className="side-item" key="15">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Amangansett</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="16">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="17">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>East Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="18">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Montuak</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="19">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sagaponack</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="20">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="21">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item> */}
                  </Menu.Item>
                </Menu>
              </div>
          </div>
          <div className="border border-danger graph-col">
          
              {ShowChart === true ? (
                <>
                  <div className="col-2-wrapper">
                    <div className="chart-header d-lg-flex justify-content-between px-3">
                      <div className="py-2 week-web">
                        <div className="chart-dropdown1 d-block d-md-inline d-lg-inline border border-secondary p-2">
                          <Dropdown overlay={menu}>
                            <a
                              className="ant-dropdown-link text-secondary"
                              onClick={(e) => e.preventDefault()}
                            >
                              Weekly/Monthly <DownOutlined />
                            </a>
                          </Dropdown>
                        </div>
                        <div className="chart-dropdown2 d-block d-md-inline d-lg-inline border border-secondary p-2 ">
                          <Dropdown overlay={menu2}>
                            <a
                              className="ant-dropdown-link text-secondary"
                              onClick={(e) => e.preventDefault()}
                            >
                              Types of Listing <DownOutlined />
                            </a>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="py-2 week-mob">
                        <div className="chart-dropdown1 d-block d-md-inline d-lg-inline border  ">
                          <Menu
                            style={{ width: "auto" }}
                            // openKeys={this.openKeys}
                            // onOpenChange={this.onOpenChange}
                            mode="inline"
                          >
                            <SubMenu key="sub2" title="Weekly/Monthly">
                              <SubMenu key="sub3" title="Weekly">
                                <Menu.Item key="1">Option 7</Menu.Item>
                                <Menu.Item key="2">Option 8</Menu.Item>
                              </SubMenu>
                              <SubMenu key="sub4" title="Monthly">
                                <Menu.Item key="3">Option 7</Menu.Item>
                                <Menu.Item key="4">Option 8</Menu.Item>
                              </SubMenu>
                            </SubMenu>
                          </Menu>
                        </div>
                        <div className="chart-dropdown2 d-block d-md-inline d-lg-inline border   ">
                          <Menu
                            style={{ width: "auto" }}
                            // openKeys={this.openKeys}
                            // onOpenChange={this.onOpenChange}
                            mode="inline"
                          >
                            <SubMenu key="sub2" title="Types of Listings">
                              <SubMenu key="sub3" title="Weekly">
                                <Menu.Item key="1">Option 7</Menu.Item>
                                <Menu.Item key="2">Option 8</Menu.Item>
                              </SubMenu>
                              <SubMenu key="sub4" title="Monthly">
                                <Menu.Item key="3">Option 7</Menu.Item>
                                <Menu.Item key="4">Option 8</Menu.Item>
                              </SubMenu>
                            </SubMenu>
                          </Menu>
                        </div>
                      </div>
                      <div className="chart-btn-div">
                        <button
                          className={
                            chartType === true
                              ? "btn-chart-1"
                              : "btn-chart-2"
                          }
                          onClick={() => {
                            this.setState({ chartType: true });
                          }}
                        >
                          <BarChartOutlined style={{ fontSize: "20px" }} />
                        </button>
                        <button
                          className={
                            chartType === false
                              ? "btn-chart-1"
                              : "btn-chart-2"
                          }
                          onClick={() => {
                            setchartType(false)
                          }}
                        >
                          <LineChartOutlined style={{ fontSize: "20px" }} />
                        </button>
                      </div>
                    </div>
                    <div className="date-header p-3 my-3">
                      <div className=" d-lg-flex justify-content-between">
                        <div className="">
                          <h3>Sep 2020 - Sep 2021</h3>
                          <h5>New Listing-Listing Volume</h5>
                        </div>
                        <div className="d-lg-inline d-md-inline d-none">
                          <button
                            className="btn-download"
                            onClick={() =>
                              setdownloadModal(true)
                            }
                          >
                            Download{" "}
                            <DownloadOutlined style={{ fontSize: "15px" }} />
                          </button>
                        </div>
                      </div>
                    </div>
                    {chartType === true ? (
                      <div className="chart-container">
                        <Bar
                          data={chartBar}
                          options={{
                            scales: {
                              x: {
                                beginAtZero: true,
                                grid: {
                                  display: false,
                                },
                              },
                            },
                            maintainAspectRatio: false,

                            title: {
                              display: true,
                              text: "Average Salse per month",
                              fontSize: 20,
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div className="chart-container">
                        <Line
                          data={chartLine}
                          // height={"100px"}
                          options={{
                            elements: {
                              point: {
                                radius: 7,
                              },
                            },
                            maintainAspectRatio: false,
                            scales: {
                              x: {
                                beginAtZero: true,
                                grid: {
                                  display: false,
                                },
                              },
                            },

                            title: {
                              display: true,
                              text: "Average Salse per month",
                              fontSize: 2,
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                    <div className=" d-block mt-4 d-md-none d-lg-none text-center">
                      <button
                        className="btn-download"
                        onClick={() => setdownloadModal(true)}
                      >
                        Download{" "}
                        <DownloadOutlined style={{ fontSize: "15px" }} />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-wrapper-blank text-center">
                    <div className="img-wrapper my-5">
                      <img src={graphIcon} className="m-auto" />
                    </div>
                    <div className="bottom-text text-secondary">
                      Welcome to Hamptons Market Data! Please select the options
                      from the left menu to view the Hamptons real estate market
                      trends and activity.
                    </div>
                  </div>
                </>
              )}
            
          </div>
        </div>
    {/* </div> */}
    
    </>
  );
};

export default Home_TEMP;
