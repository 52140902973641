import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {ApiCall} from '../../utils/api'


const initialState = {
    pricePointData:[],
    loading:false
  }

  export const getPricePointData = createAsyncThunk(
    
    'HMD/getPricePointData',
    
    async (body,thunkAPI) => {
    
    const response=await ApiCall("GET",body,"/api/priceRange/list","").then((res)=>res)
    // console.log('response', response.data.data)
    
    return response.data.data
  })

  export const pricePointSlice=createSlice({
      name:"HMD",
      initialState,
      reducers:{
  
      },
      extraReducers:{
        [getPricePointData.pending]: (state) => {
            state.loading = true
          },
          [getPricePointData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.pricePointData = payload
          },
          [getPricePointData.rejected]: (state, {payload}) => {
            state.loading = false
          },
      }
  })

  export const pricePointReducer=pricePointSlice.reducer