import axios from "axios";
import { toast } from "react-toastify";

export const ApiCall = async (method, body, endPoint, params, Token) => {
    try {
      const token = localStorage.getItem("Authorization");
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
      } else {
        axios.defaults.headers.common["Authorization"] = null;
      }
  
      // console.log("APICALL===>", method,params, body, endPoint, Token);
      // console.log("params===>", params,);
      const { data } = await axios({
        method: method,
        url: process.env.REACT_APP_BASE_URL + endPoint,
        data: body,
        // headers: {
        //   "Content-Type": "application/json",
        // },
        params: params,
      });
  
      if (data.code === 200) {
        return {
          data: data,
          status: true,
        };
      }
      if (data.code === 401) {
        window.location.href = "/";
      } else {
        return {
          data: data,
          status: false,
        };
      }
    } catch (error) {
      if (error.response) {
        return {
          data: {},
          status: false,
          error: error.response.data.errors,
        };
      }
      toast.error(error);
      return error;
    }
  };