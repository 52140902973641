import React, { useState, useEffect } from "react";
import {
  Tabs,
  Radio,
  Card,
  Collapse,
  DatePicker,
  Menu,
  Dropdown,
  Space,
  Spin,
} from "antd";
import "../Assets/css/reports.scss";
import { Line, Bar } from "react-chartjs-2";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotalMarketTrends,
  getMarketMovementByLocation,
  getMarketMovementByPricePoint,
  getSupply
} from "../redux/Reports_slice/reports";
import cloneDeep from "lodash/cloneDeep";
import {
  BarChartOutlined,
  ArrowRightOutlined,
  LineChartOutlined,
  DownloadOutlined,
  DownOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { getlocationData } from "../redux/market_location_slice/location";
import { getPricePointData } from "../redux/markey_pricepoint_slice/pricepoint";
// import {supply} from './temp_data'

const Reports = () => {
  const [collapseToggle, setCollapseToggle] = useState({
    collapseActiveKey1: false,
    collapseActiveKey2: false,
    collapseActiveKey3: false,
    collapseActiveKey4: false,
  });

  const [reportView, setreportView] = useState("monthly");
  // console.log("reportView", reportView);
  const [startDate, setStartDate] = useState();
  console.log("startDate", startDate);
  const [endDate, setEndDate] = useState(moment());
  console.log("endDate", endDate);
  const [activeTab, setActiveTab] = useState("1");

  const [showLocation, setshowLocation] = useState(false);
  const [LocationFilter, setLocationFilter] = useState();

  const [DurationLabel, setDurationLabel] = useState();

  const [showPricePoint, setshowPricePoint] = useState(false);
  const [PricePointFilter, setPricePointFilter] = useState();

  const [supplyFilter, setsupplyFilter] = useState(false);

  //state total market trends

  const [graph1, setgraph1] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  // console.log(graph1)
  const [graph2, setgraph2] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: "New Listings",
        lineTension: 0.5,
        backgroundColor: ["#0E5D72"],
        borderColor: "#0E5D72",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Contract Signed",
        lineTension: 0.5,
        backgroundColor: ["#008C85"],
        borderColor: "#008C85",
        borderWidth: 1,
        data: [111, 259, 380, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Sold Listings",
        lineTension: 0.5,
        backgroundColor: ["#2888B7"],
        borderColor: "#2888B7",
        borderWidth: 1,
        data: [111, 259, 112, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [graph4, setgraph4] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  const [graph3, setgraph3] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  //Sold Listing graphs states

  const [graph1_SoldListing, setgraph1_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [graph2_SoldListing, setgraph2_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph3_SoldListing, setgraph3_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph4_SoldListing, setgraph4_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph5_SoldListing, setgraph5_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph6_SoldListing, setgraph6_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //contract signed

  const [graph1_contract, setgraph1_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph2_contract, setgraph2_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph3_contract, setgraph3_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph4_contract, setgraph4_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //new listing state

  const [graph1_listings, setgraph1_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph2_listings, setgraph2_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph3_listings, setgraph3_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [graph4_listings, setgraph4_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //location state and total

  const [Locationgraph1, setLocationgraph1] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  // console.log(Locationgraph1)
  const [Locationgraph2, setLocationgraph2] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: "New Listings",
        lineTension: 0.5,
        backgroundColor: ["#0E5D72"],
        borderColor: "#0E5D72",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Contract Signed",
        lineTension: 0.5,
        backgroundColor: ["#008C85"],
        borderColor: "#008C85",
        borderWidth: 1,
        data: [111, 259, 380, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Sold Listings",
        lineTension: 0.5,
        backgroundColor: ["#2888B7"],
        borderColor: "#2888B7",
        borderWidth: 1,
        data: [111, 259, 112, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [Locationgraph4, setLocationgraph4] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  const [Locationgraph3, setLocationgraph3] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  //Sold Listing Locationgraphs states

  const [Locationgraph1_SoldListing, setLocationgraph1_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [Locationgraph2_SoldListing, setLocationgraph2_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph3_SoldListing, setLocationgraph3_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph4_SoldListing, setLocationgraph4_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph5_SoldListing, setLocationgraph5_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph6_SoldListing, setLocationgraph6_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //contract signed

  const [Locationgraph1_contract, setLocationgraph1_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph2_contract, setLocationgraph2_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph3_contract, setLocationgraph3_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph4_contract, setLocationgraph4_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //new listing state

  const [Locationgraph1_listings, setLocationgraph1_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph2_listings, setLocationgraph2_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph3_listings, setLocationgraph3_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [Locationgraph4_listings, setLocationgraph4_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  // price state graph state

  const [PricePointgraph1, setPricePointgraph1] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  // console.log(PricePointgraph1)
  const [PricePointgraph2, setPricePointgraph2] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: "New Listings",
        lineTension: 0.5,
        backgroundColor: ["#0E5D72"],
        borderColor: "#0E5D72",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Contract Signed",
        lineTension: 0.5,
        backgroundColor: ["#008C85"],
        borderColor: "#008C85",
        borderWidth: 1,
        data: [111, 259, 380, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Sold Listings",
        lineTension: 0.5,
        backgroundColor: ["#2888B7"],
        borderColor: "#2888B7",
        borderWidth: 1,
        data: [111, 259, 112, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [PricePointgraph4, setPricePointgraph4] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  const [PricePointgraph3, setPricePointgraph3] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#84C5DD"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#408399"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  //Sold Listing PricePointgraphs states

  const [PricePointgraph1_SoldListing, setPricePointgraph1_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [PricePointgraph2_SoldListing, setPricePointgraph2_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph3_SoldListing, setPricePointgraph3_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph4_SoldListing, setPricePointgraph4_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph5_SoldListing, setPricePointgraph5_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph6_SoldListing, setPricePointgraph6_SoldListing] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //contract signed

  const [PricePointgraph1_contract, setPricePointgraph1_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph2_contract, setPricePointgraph2_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph3_contract, setPricePointgraph3_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph4_contract, setPricePointgraph4_contract] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //new listing state

  const [PricePointgraph1_listings, setPricePointgraph1_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph2_listings, setPricePointgraph2_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph3_listings, setPricePointgraph3_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  const [PricePointgraph4_listings, setPricePointgraph4_listings] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  //end price

  //supply graphstate

  const [Supplygraph1, setSupplygraph1] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#27688D"],
        // borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });
  // console.log(Supplygraph1)
  const [Supplygraph2, setSupplygraph2] = useState({
    labels: [
      "Total Market"
    ],
    datasets: [
      {
        label: "New Listings",
        stack: 1,
        lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        // borderColor: "#0E5D72",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Contract Signed",
        stack: 1,
        lineTension: 0.5,
        backgroundColor: ["#27688D"],
        // borderColor: "#008C85",
        borderWidth: 1,
        data: [111, 259, 380, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
      {
        label: "Sold Listings",
        stack: 1,
        lineTension: 0.5,
        backgroundColor: ["#2E2E2E"],
        // borderColor: "#2888B7",
        borderWidth: 1,
        data: [111, 259, 112, 222, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [Supplygraph3, setSupplygraph3] = useState({
    labels: ["<$1", "$1-2.99", "$3-4.99", "$5-9.99", "$10-19.99", ">$20"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 2,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 3,
        backgroundColor: ["#2E2E2E"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

  const [Supplygraph4, setSupplygraph4] = useState({
    labels: ["East Hampton", "Amagansett", "Southampton", "Montauk", "Bridgehampton", "Wainscott", "Sag Harbor","Water Mill","Sagaponack"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "New Listings",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#000000"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "Contract Signed",
        fill: false,
        stack: 1,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "Sold Listings",
        fill: false,
        stack: 1,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
    ],
  });

 

  const [Supplygraph5, setSupplygraph5] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        // borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [Supplygraph6, setSupplygraph6] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        // borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  });

  const [Supplygraph7, setSupplygraph7] = useState({
    labels: ["<$1", "$1-2.99", "$3-4.99", "$5-9.99", "$10-19.99", ">$20"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "April 2022",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [2, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "March 2022",
        fill: false,
        stack: 2,
        backgroundColor: ["#989898"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "February 2022",
        fill: false,
        stack: 3,
        backgroundColor: ["#727272"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "January 2022",
        fill: false,
        stack: 4,
        // lineTension: 0.5,
        backgroundColor: ["#494949"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "December 2021",
        fill: false,
        stack: 5,
        // lineTension: 0.5,
        backgroundColor: ["#2E2E2E"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "November 2021",
        fill: false,
        stack: 6,
        // lineTension: 0.5,
        backgroundColor: ["#000000"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "October 2021",
        fill: false,
        stack: 7,
        // lineTension: 0.5,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
    ],
  });
  // console.log("Supplygraph7",Supplygraph7)
  const [Supplygraph8, setSupplygraph8] = useState({
    labels: ["<$1", "$1-2.99", "$3-4.99", "$5-9.99", "$10-19.99", ">$20"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "April 2022",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "March 2022",
        fill: false,
        stack: 2,
        backgroundColor: ["#989898"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "February 2022",
        fill: false,
        stack: 3,
        backgroundColor: ["#727272"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "January 2022",
        fill: false,
        stack: 4,
        // lineTension: 0.5,
        backgroundColor: ["#494949"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "December 2021",
        fill: false,
        stack: 5,
        // lineTension: 0.5,
        backgroundColor: ["#2E2E2E"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "November 2021",
        fill: false,
        stack: 6,
        // lineTension: 0.5,
        backgroundColor: ["#000000"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "October 2021",
        fill: false,
        stack: 7,
        // lineTension: 0.5,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
    ],
  });

  //graph 9 supply

  const [Supplygraph9, setSupplygraph9] = useState({
    labels: ["East Hampton", "Amagansett", "Southampton", "Montauk", "Bridgehampton", "Wainscott", "Sag Harbor","Water Mill","Sagaponack"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "April 2022",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "March 2022",
        fill: false,
        stack: 2,
        backgroundColor: ["#989898"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "February 2022",
        fill: false,
        stack: 3,
        backgroundColor: ["#727272"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "January 2022",
        fill: false,
        stack: 4,
        // lineTension: 0.5,
        backgroundColor: ["#494949"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "December 2021",
        fill: false,
        stack: 5,
        // lineTension: 0.5,
        backgroundColor: ["#2E2E2E"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "November 2021",
        fill: false,
        stack: 6,
        // lineTension: 0.5,
        backgroundColor: ["#000000"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "October 2021",
        fill: false,
        stack: 7,
        // lineTension: 0.5,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
    ],
  });

  const [Supplygraph10, setSupplygraph10] = useState({
    labels: ["East Hampton", "Amagansett", "Southampton", "Montauk", "Bridgehampton", "Wainscott", "Sag Harbor","Water Mill","Sagaponack"],
    datasets: [
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "April 2022",
        fill: false,
        stack: 1,
        // lineTension: 0.5,
        backgroundColor: ["#C4C4C4"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [2, 344, 100, 299, 467, 366, 234],
      },
      {
        label: "March 2022",
        fill: false,
        stack: 2,
        backgroundColor: ["#989898"],
        borderColor: "rgba(0,0,0,1)",
        data: [380, 481, 344, 100, 299, 467, 366],
      },
      {
        label: "February 2022",
        fill: false,
        stack: 3,
        backgroundColor: ["#727272"],
        borderColor: "rgba(0,0,0,1)",
        data: [259, 380, 481, 156, 255, 344, , 467],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "January 2022",
        fill: false,
        stack: 4,
        // lineTension: 0.5,
        backgroundColor: ["#494949"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "December 2021",
        fill: false,
        stack: 5,
        // lineTension: 0.5,
        backgroundColor: ["#2E2E2E"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "November 2021",
        fill: false,
        stack: 6,
        // lineTension: 0.5,
        backgroundColor: ["#000000"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
      {
        // barPercentage: 0.5,
        // categoryPercentage: 1.0,
        label: "October 2021",
        fill: false,
        stack: 7,
        // lineTension: 0.5,
        backgroundColor: ["#27688D"],
        borderColor: "rgba(0,0,0,1)",
        // borderWidth:  2,
        data: [255, 344, 100, 299, 467, 366, 234],
      },
    ],
  });

  const dispatch = useDispatch();
  const { totalMarketTrends, marketMovementByLocation,marketMovementByPricePoint ,supply } = useSelector(
    (state) => state?.Report
    );
    console.log('supply', supply)
  const { loading1, loading2,loading3,loading4 } = useSelector((state) => state?.Report);
  console.log("marketMovementByLocation", marketMovementByLocation);

  //  dispatch(getTotalMarketTrends())
  //   useEffect(() => {
  // console.log("reportview useEffect")
  //     dispatch(
  //       getTotalMarketTrends({
  //         startDate: startDate,
  //         endDate: endDate,
  //         graphType: reportView ? "weekly" : "monthly",
  //       })
  //     );
  //   }, [reportView]);

  // useEffect(()=>{
  //   var currentDate = moment();

  //   if (reportView) {
  //     var weekStart = currentDate.clone().startOf("isoWeek");
  //     console.log("weekStart", weekStart);
  //     var previousDay = moment(weekStart).subtract(7, "days");
  //     console.log("previousDay", previousDay);
  //     setStartDate(previousDay);
  //     setEndDate(weekStart);
  //   } else {
  //     var weekStart = currentDate.clone().startOf("isoWeek");
  //     console.log("weekStart", weekStart);
  //     var previousDay = moment(weekStart).subtract(31, "days");
  //     console.log("previousDay", previousDay);
  //     setStartDate(previousDay);
  //     setEndDate(weekStart);
  //   }
  // },[])

  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const { SubMenu } = Menu;

  const chartBar = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        // borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };
  const chartLine = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };

  const gridStyle = {
    // width: "15%",
    // margin: "5px",
    // padding: "10px",
    // textAlign: "left",
    // backgroundColor: "white",
    // zIndex: "1",
    // boxShadow:
    //     "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)",
  };

  const handleCollapse = (name, value) => {
    console.log("name", name);

    console.log("value", value);

    if (value == 1) {
      return setCollapseToggle({ ...collapseToggle, collapseActiveKey1: true });
    } else if (value == undefined && name === "collapse1") {
      return setCollapseToggle({
        ...collapseToggle,
        collapseActiveKey1: false,
      });
    }
    if (value == 2) {
      return setCollapseToggle({ ...collapseToggle, collapseActiveKey2: true });
    } else if (value == undefined && name === "collapse2") {
      return setCollapseToggle({
        ...collapseToggle,
        collapseActiveKey2: false,
      });
    }
    if (value == 3) {
      return setCollapseToggle({ ...collapseToggle, collapseActiveKey3: true });
    } else if (value == undefined && name === "collapse3") {
      return setCollapseToggle({
        ...collapseToggle,
        collapseActiveKey3: false,
      });
    }
    if (value == 4) {
      return setCollapseToggle({ ...collapseToggle, collapseActiveKey4: true });
    } else if (value == undefined && name === "collapse4") {
      return setCollapseToggle({
        ...collapseToggle,
        collapseActiveKey4: false,
      });
    }
  };
  const { locationData } = useSelector((state) => state?.Location);
  const { pricePointData } = useSelector((state) => state?.PricePoint);
  console.log("locationData", locationData);

  const handleTabChange = async (activeTab) => {
    console.log("activeTab dispatch", typeof activeTab);
    await setActiveTab(activeTab);
    if (activeTab === "1") {
      setshowLocation(false);
      setshowPricePoint(false);
      setsupplyFilter(false)
       dispatch(
        getTotalMarketTrends({
          startDate: startDate,
          endDate: endDate,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    } else if (activeTab === "2") {
      setshowLocation(true);
      setshowPricePoint(false);
      setsupplyFilter(false)
      dispatch(getlocationData());
       dispatch(
        getMarketMovementByLocation({
          startDate: startDate,
          endDate: endDate,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    } else if (activeTab === "3") {
      setshowPricePoint(true);
      setshowLocation(false);
      setsupplyFilter(false)
      dispatch(getPricePointData());
       dispatch(
        getMarketMovementByPricePoint({
          startDate: startDate,
          endDate: endDate,
          range:PricePointFilter,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    }   else if (activeTab === "4") {
      setshowPricePoint(false);
      setshowLocation(false);
      setsupplyFilter(true)
      dispatch(getSupply({
        startDate: startDate,
        endDate: endDate,
        graphType: reportView === "weekly" ? "weekly" : "monthly",
      }));
 
    }
  };

  // useEffect(async () => {
  //   console.log("first");

  //   if (activeTab === "1") {
  //     await dispatch(
  //       getTotalMarketTrends({
  //         startDate: startDate,
  //         endDate: endDate,
  //         graphType: reportView === "weekly" ? "weekly" : "monthly",
  //       })
  //     );
  //   }
  // }, []);
  
  useEffect(()=>{
    if(activeTab === "1"){
       dispatch(
        getTotalMarketTrends({
          startDate: startDate,
          endDate: endDate,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    }
    if(activeTab === "2"){
         dispatch(
        getMarketMovementByLocation({
          startDate: startDate,
          endDate: endDate,
          location:LocationFilter,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    }
    if(activeTab === "3"){
        dispatch(
        getMarketMovementByPricePoint({
          startDate: startDate,
          endDate: endDate,
          range:PricePointFilter,
          graphType: reportView === "weekly" ? "weekly" : "monthly",
        })
      );
    }
    if(activeTab === "4"){
      dispatch(
      getSupply({
        startDate: startDate,
        endDate: endDate,
        graphType: reportView === "weekly" ? "weekly" : "monthly",
      })
    );
  }



  },[startDate,endDate])

  // graph state change

  useEffect(async () => {
    var labels =
       totalMarketTrends?.market_movement?.data?.chartData?.graph1?.data.data.map(
        (elem) => {
          if (reportView === "weekly") {
            let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
              elem.end_date
            ).format("MMM-DD")}`;
            return leb;
          } else {
            return elem?.name;
          }
        }
      );

    var value =
       totalMarketTrends?.market_movement?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );
    // console.log(labels)
    // console.log(value)

    let cloneGraph = cloneDeep(graph1);
    cloneGraph.labels = labels;
    cloneGraph.datasets[0].data = value;

    setgraph1(cloneGraph);

    //Graph2

    // var labelsGraph2 =
    //    totalMarketTrends?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.name
    //   );
    // console.log("labelsGraph2", labelsGraph2);
    var graph2In_contractValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph2?.data.map(
        (elem) => elem?.in_contract.value
      );
    var graph2new_listingValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph2?.data.map(
        (elem) => elem?.new_listing.value
      );
    var graph2saleValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph2?.data.map(
        (elem) => elem?.sale.value
      );

    let cloneGraph2 = cloneDeep(graph2);
    cloneGraph2.labels = labels;
    cloneGraph2.datasets[0].data = graph2new_listingValue;
    cloneGraph2.datasets[1].data = graph2In_contractValue;
    cloneGraph2.datasets[2].data = graph2saleValue;

    console.log("cloneGraph2", cloneGraph2);
    setgraph2(cloneGraph2);

    //Graph4

    var labelsGraph4 =
       totalMarketTrends?.market_movement?.data?.chartData?.graph4?.data.map(
        (elem) => elem?.name
      );

    var graph4In_contractValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph4?.data.map(
        (elem) => elem?.in_contract.value
      );
    var graph4new_listingValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph4?.data.map(
        (elem) => elem?.new_listing.value
      );
    var graph4saleValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph4?.data.map(
        (elem) => elem?.sale.value
      );

    let cloneGraph4 = cloneDeep(graph4);
    cloneGraph4.labels = labelsGraph4;
    cloneGraph4.datasets[0].data = graph4new_listingValue;
    cloneGraph4.datasets[1].data = graph4In_contractValue;
    cloneGraph4.datasets[2].data = graph4saleValue;

    console.log("cloneGraph2", cloneGraph4);
    setgraph4(cloneGraph4);

    //Graph3

    var labelsGraph3 =
       totalMarketTrends?.market_movement?.data?.chartData?.graph3?.data.map(
        (elem) => elem?.name
      );

    var graph3In_contractValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph3?.data.map(
        (elem) => elem?.in_contract.value
      );
    var graph3new_listingValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph3?.data.map(
        (elem) => elem?.new_listing.value
      );
    var graph3saleValue =
       totalMarketTrends?.market_movement?.data?.chartData?.graph3?.data.map(
        (elem) => elem?.sale.value
      );

    let cloneGraph3 = cloneDeep(graph3);
    cloneGraph3.labels = labelsGraph3;
    cloneGraph3.datasets[0].data = graph3new_listingValue;
    cloneGraph3.datasets[1].data = graph3In_contractValue;
    cloneGraph3.datasets[2].data = graph3saleValue;

    console.log("cloneGraph2", cloneGraph3);
    setgraph3(cloneGraph3);

    // sold listing setstate

    var graph1_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph1Sold = cloneDeep(graph1_SoldListing);
    cloneGraph1Sold.labels = labels;
    cloneGraph1Sold.datasets[0].data = graph1_soldVal;
    setgraph1_SoldListing(cloneGraph1Sold);

    var graph2_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph2Sold = cloneDeep(graph2_SoldListing);
    cloneGraph2Sold.labels = labels;
    cloneGraph2Sold.datasets[0].data = graph2_soldVal;
    setgraph2_SoldListing(cloneGraph2Sold);

    var graph3_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph3Sold = cloneDeep(graph2_SoldListing);
    cloneGraph3Sold.labels = labels;
    cloneGraph3Sold.datasets[0].data = graph3_soldVal;
    setgraph3_SoldListing(cloneGraph3Sold);

    var graph4_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph4Sold = cloneDeep(graph4_SoldListing);
    cloneGraph4Sold.labels = labels;
    cloneGraph4Sold.datasets[0].data = graph4_soldVal;
    setgraph4_SoldListing(cloneGraph4Sold);

    var graph5_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph5?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph5Sold = cloneDeep(graph5_SoldListing);
    cloneGraph5Sold.labels = labels;
    cloneGraph5Sold.datasets[0].data = graph5_soldVal;
    setgraph5_SoldListing(cloneGraph5Sold);

    var graph6_soldVal =
       totalMarketTrends?.sold_listings?.data?.chartData?.graph6?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph6Sold = cloneDeep(graph6_SoldListing);
    cloneGraph6Sold.labels = labels;
    cloneGraph6Sold.datasets[0].data = graph6_soldVal;
    setgraph6_SoldListing(cloneGraph6Sold);

    //contract signed

    var graph1_contractVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph1contract = cloneDeep(graph1_contract);
    cloneGraph1contract.labels = labels;
    cloneGraph1contract.datasets[0].data = graph1_contractVal;
    setgraph1_contract(cloneGraph1contract);

    var graph2_contractVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph2contract = cloneDeep(graph2_contract);
    cloneGraph2contract.labels = labels;
    cloneGraph2contract.datasets[0].data = graph2_contractVal;
    setgraph2_contract(cloneGraph2contract);

    var graph3_contractVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph3contract = cloneDeep(graph3_contract);
    cloneGraph3contract.labels = labels;
    cloneGraph3contract.datasets[0].data = graph3_contractVal;
    setgraph3_contract(cloneGraph3contract);

    var graph4_contractVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph4contract = cloneDeep(graph4_contract);
    cloneGraph4contract.labels = labels;
    cloneGraph4contract.datasets[0].data = graph4_contractVal;
    setgraph4_contract(cloneGraph4contract);

    //new listings

    var graph1_listingVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph1Newlisting = cloneDeep(graph1_listings);
    cloneGraph1Newlisting.labels = labels;
    cloneGraph1Newlisting.datasets[0].data = graph1_listingVal;
    setgraph1_listings(cloneGraph1Newlisting);

    var graph2_listingVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph2Newlisting = cloneDeep(graph2_listings);
    cloneGraph2Newlisting.labels = labels;
    cloneGraph2Newlisting.datasets[0].data = graph2_listingVal;
    setgraph2_listings(cloneGraph2Newlisting);

    var graph3_listingVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph3Newlisting = cloneDeep(graph3_listings);
    cloneGraph3Newlisting.labels = labels;
    cloneGraph3Newlisting.datasets[0].data = graph3_listingVal;
    setgraph3_listings(cloneGraph3Newlisting);

    var graph4_listingVal =
       totalMarketTrends?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let cloneGraph4Newlisting = cloneDeep(graph4_listings);
    cloneGraph4Newlisting.labels = labels;
    cloneGraph4Newlisting.datasets[0].data = graph4_listingVal;
    setgraph4_listings(cloneGraph4Newlisting);

    //end total maeket

    var labelsLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph1?.data.data.map(
        (elem) => {
          if (reportView === "weekly") {
            let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
              elem.end_date
            ).format("MMM-DD")}`;
            return leb;
          } else {
            return elem?.name;
          }
        }
      );

    // var valueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph1?.data.data.map(
    //     (elem) => elem?.value
    //   );
    // // console.log(labelsLocation)
    // // console.log(value)

    // let LocationcloneGraph = cloneDeep(Locationgraph1);
    // LocationcloneGraph.labels = labelsLocation;
    // LocationcloneGraph.datasets[0].data = valueLocation;

    // setLocationgraph1(LocationcloneGraph);

    // //Graph2

    // // var labelsGraph2 =
    // //    marketMovementByLocation?.market_movement?.data?.chartData?.graph2?.data.map(
    // //     (elem) => elem?.name
    // //   );
    // // console.log("labelsGraph2", labelsGraph2);
    // var graph2In_contractValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph2new_listingValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph2saleValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let LocationcloneGraph2 = cloneDeep(Locationgraph2);
    // LocationcloneGraph2.labels = labelsLocation;
    // LocationcloneGraph2.datasets[0].data = graph2new_listingValueLocation;
    // LocationcloneGraph2.datasets[1].data = graph2In_contractValueLocation;
    // LocationcloneGraph2.datasets[2].data = graph2saleValueLocation;

    // console.log("LocationcloneGraph2", LocationcloneGraph2);
    // setLocationgraph2(LocationcloneGraph2);

    // //Graph4

    // var labelsGraph4Location =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.name
    //   );

    // var graph4In_contractValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph4new_listingValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph4saleValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let LocationcloneGraph4 = cloneDeep(Locationgraph4);
    // LocationcloneGraph4.labels = labelsGraph4Location;
    // LocationcloneGraph4.datasets[0].data = graph4new_listingValueLocation;
    // LocationcloneGraph4.datasets[1].data = graph4In_contractValueLocation;
    // LocationcloneGraph4.datasets[2].data = graph4saleValueLocation;

    // console.log("cloneGraph2", LocationcloneGraph4);
    // setLocationgraph4(LocationcloneGraph4);

    // //Graph3

    // var labelsGraph3Location =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.name
    //   );

    // var graph3In_contractValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph3new_listingValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph3saleValueLocation =
    //    marketMovementByLocation?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let LocationcloneGraph3 = cloneDeep(Locationgraph3);
    // LocationcloneGraph3.labels = labelsGraph3Location;
    // LocationcloneGraph3.datasets[0].data = graph3new_listingValueLocation;
    // LocationcloneGraph3.datasets[1].data = graph3In_contractValueLocation;
    // LocationcloneGraph3.datasets[2].data = graph3saleValueLocation;

    // console.log("cloneGraph2", LocationcloneGraph3);
    // setLocationgraph3(LocationcloneGraph3);

    // sold listing setstate

    var graph1_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph1Sold = cloneDeep(Locationgraph1_SoldListing);
    LocationcloneGraph1Sold.labels = labelsLocation;
    LocationcloneGraph1Sold.datasets[0].data = graph1_soldValLocation;
    setLocationgraph1_SoldListing(LocationcloneGraph1Sold);

    var graph2_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph2Sold = cloneDeep(Locationgraph2_SoldListing);
    LocationcloneGraph2Sold.labels = labelsLocation;
    LocationcloneGraph2Sold.datasets[0].data = graph2_soldValLocation;
    setLocationgraph2_SoldListing(LocationcloneGraph2Sold);

    var graph3_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph3Sold = cloneDeep(Locationgraph3_SoldListing);
    LocationcloneGraph3Sold.labels = labelsLocation;
    LocationcloneGraph3Sold.datasets[0].data = graph3_soldValLocation;
    setLocationgraph3_SoldListing(LocationcloneGraph3Sold);

    var graph4_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph4Sold = cloneDeep(Locationgraph4_SoldListing);
    LocationcloneGraph4Sold.labels = labelsLocation;
    LocationcloneGraph4Sold.datasets[0].data = graph4_soldValLocation;
    setLocationgraph4_SoldListing(LocationcloneGraph4Sold);

    var graph5_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph5?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph5Sold = cloneDeep(Locationgraph5_SoldListing);
    LocationcloneGraph5Sold.labels = labelsLocation;
    LocationcloneGraph5Sold.datasets[0].data = graph5_soldValLocation;
    setLocationgraph5_SoldListing(LocationcloneGraph5Sold);

    var graph6_soldValLocation =
       marketMovementByLocation?.sold_listings?.data?.chartData?.graph6?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph6Sold = cloneDeep(Locationgraph6_SoldListing);
    LocationcloneGraph6Sold.labels = labelsLocation;
    LocationcloneGraph6Sold.datasets[0].data = graph6_soldValLocation;
    setLocationgraph6_SoldListing(LocationcloneGraph6Sold);

    //contract signed

    var graph1_contractValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph1contract = cloneDeep(Locationgraph1_contract);
    LocationcloneGraph1contract.labels = labelsLocation;
    LocationcloneGraph1contract.datasets[0].data = graph1_contractValLocation;
    setLocationgraph1_contract(LocationcloneGraph1contract);

    var graph2_contractValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph2contract = cloneDeep(Locationgraph2_contract);
    LocationcloneGraph2contract.labels = labelsLocation;
    LocationcloneGraph2contract.datasets[0].data = graph2_contractValLocation;
    setLocationgraph2_contract(LocationcloneGraph2contract);

    var graph3_contractValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph3contract = cloneDeep(Locationgraph3_contract);
    LocationcloneGraph3contract.labels = labelsLocation;
    LocationcloneGraph3contract.datasets[0].data = graph3_contractValLocation;
    setLocationgraph3_contract(LocationcloneGraph3contract);

    var graph4_contractValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph4contract = cloneDeep(Locationgraph4_contract);
    LocationcloneGraph4contract.labels = labelsLocation;
    LocationcloneGraph4contract.datasets[0].data = graph4_contractValLocation;
    setLocationgraph4_contract(LocationcloneGraph4contract);

    //new listings

    var graph1_listingValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph1Newlisting = cloneDeep(Locationgraph1_listings);
    LocationcloneGraph1Newlisting.labels = labelsLocation;
    LocationcloneGraph1Newlisting.datasets[0].data = graph1_listingValLocation;
    setLocationgraph1_listings(LocationcloneGraph1Newlisting);

    var graph2_listingValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph2Newlisting = cloneDeep(Locationgraph2_listings);
    LocationcloneGraph2Newlisting.labels = labelsLocation;
    LocationcloneGraph2Newlisting.datasets[0].data = graph2_listingValLocation;
    setLocationgraph2_listings(LocationcloneGraph2Newlisting);

    var graph3_listingValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph3Newlisting = cloneDeep(Locationgraph3_listings);
    LocationcloneGraph3Newlisting.labels = labelsLocation;
    LocationcloneGraph3Newlisting.datasets[0].data = graph3_listingValLocation;
    setLocationgraph3_listings(LocationcloneGraph3Newlisting);

    var graph4_listingValLocation =
       marketMovementByLocation?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let LocationcloneGraph4Newlisting = cloneDeep(Locationgraph4_listings);
    LocationcloneGraph4Newlisting.labels = labelsLocation;
    LocationcloneGraph4Newlisting.datasets[0].data = graph4_listingValLocation;
    setLocationgraph4_listings(LocationcloneGraph4Newlisting);


    //end location

    var labelsPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph1?.data.data.map(
        (elem) => {
          if (reportView === "weekly") {
            let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
              elem.end_date
            ).format("MMM-DD")}`;
            return leb;
          } else {
            return elem?.name;
          }
        }
      );

    // var valuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph1?.data.data.map(
    //     (elem) => elem?.value
    //   );
    // // console.log(labelsPricePoint)
    // // console.log(value)

    // let PricePointcloneGraph = cloneDeep(PricePointgraph1);
    // PricePointcloneGraph.labels = labelsPricePoint;
    // PricePointcloneGraph.datasets[0].data = valuePricePoint;

    // setPricePointgraph1(PricePointcloneGraph);

    // //Graph2

    // // var labelsGraph2 =
    // //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph2?.data.map(
    // //     (elem) => elem?.name
    // //   );
    // // console.log("labelsGraph2", labelsGraph2);
    // var graph2In_contractValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph2new_listingValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph2saleValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph2?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let PricePointcloneGraph2 = cloneDeep(PricePointgraph2);
    // PricePointcloneGraph2.labels = labelsPricePoint;
    // PricePointcloneGraph2.datasets[0].data = graph2new_listingValuePricePoint;
    // PricePointcloneGraph2.datasets[1].data = graph2In_contractValuePricePoint;
    // PricePointcloneGraph2.datasets[2].data = graph2saleValuePricePoint;

    // console.log("PricePointcloneGraph2", PricePointcloneGraph2);
    // setPricePointgraph2(PricePointcloneGraph2);

    // //Graph4

    // var labelsGraph4PricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.name
    //   );

    // var graph4In_contractValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph4new_listingValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph4saleValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph4?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let PricePointcloneGraph4 = cloneDeep(PricePointgraph4);
    // PricePointcloneGraph4.labels = labelsGraph4PricePoint;
    // PricePointcloneGraph4.datasets[0].data = graph4new_listingValuePricePoint;
    // PricePointcloneGraph4.datasets[1].data = graph4In_contractValuePricePoint;
    // PricePointcloneGraph4.datasets[2].data = graph4saleValuePricePoint;

    // console.log("cloneGraph2", PricePointcloneGraph4);
    // setPricePointgraph4(PricePointcloneGraph4);

    // //Graph3

    // var labelsGraph3PricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.name
    //   );

    // var graph3In_contractValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.in_contract.value
    //   );
    // var graph3new_listingValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.new_listing.value
    //   );
    // var graph3saleValuePricePoint =
    //    marketMovementByPricePoint?.market_movement?.data?.chartData?.graph3?.data.map(
    //     (elem) => elem?.sale.value
    //   );

    // let PricePointcloneGraph3 = cloneDeep(PricePointgraph3);
    // PricePointcloneGraph3.labels = labelsGraph3PricePoint;
    // PricePointcloneGraph3.datasets[0].data = graph3new_listingValuePricePoint;
    // PricePointcloneGraph3.datasets[1].data = graph3In_contractValuePricePoint;
    // PricePointcloneGraph3.datasets[2].data = graph3saleValuePricePoint;

    // console.log("cloneGraph2", PricePointcloneGraph3);
    // setPricePointgraph3(PricePointcloneGraph3);

    // sold listing setstate

    var graph1_soldValPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph1Sold = cloneDeep(PricePointgraph1_SoldListing);
    PricePointcloneGraph1Sold.labels = labelsPricePoint;
    PricePointcloneGraph1Sold.datasets[0].data = graph1_soldValPricePoint;
    setPricePointgraph1_SoldListing(PricePointcloneGraph1Sold);

    var graph2_soldValPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph2Sold = cloneDeep(PricePointgraph2_SoldListing);
    PricePointcloneGraph2Sold.labels = labelsPricePoint;
    PricePointcloneGraph2Sold.datasets[0].data = graph2_soldValPricePoint;
    setPricePointgraph2_SoldListing(PricePointcloneGraph2Sold);

    var graph3_soldValPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph3Sold = cloneDeep(Locationgraph3_SoldListing);
    PricePointcloneGraph3Sold.labels = labelsPricePoint;
    PricePointcloneGraph3Sold.datasets[0].data = graph3_soldValPricePoint;
    setLocationgraph3_SoldListing(PricePointcloneGraph3Sold);

    var graph4_soldValPricePoint =  
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph4Sold = cloneDeep(PricePointgraph4_SoldListing);
    PricePointcloneGraph4Sold.labels = labelsPricePoint;
    PricePointcloneGraph4Sold.datasets[0].data = graph4_soldValPricePoint;
    setPricePointgraph4_SoldListing(PricePointcloneGraph4Sold);

    var graph5_soldValPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph5?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph5Sold = cloneDeep(PricePointgraph5_SoldListing);
    PricePointcloneGraph5Sold.labels = labelsPricePoint;
    PricePointcloneGraph5Sold.datasets[0].data = graph5_soldValPricePoint;
    setPricePointgraph5_SoldListing(PricePointcloneGraph5Sold);

    var graph6_soldValPricePoint =
       marketMovementByPricePoint?.sold_listings?.data?.chartData?.graph6?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph6Sold = cloneDeep(PricePointgraph6_SoldListing);
    PricePointcloneGraph6Sold.labels = labelsPricePoint;
    PricePointcloneGraph6Sold.datasets[0].data = graph6_soldValPricePoint;
    setPricePointgraph6_SoldListing(PricePointcloneGraph6Sold);

    //contract signed

    var graph1_contractValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph1contract = cloneDeep(PricePointgraph1_contract);
    PricePointcloneGraph1contract.labels = labelsPricePoint;
    PricePointcloneGraph1contract.datasets[0].data = graph1_contractValPricePoint;
    setPricePointgraph1_contract(PricePointcloneGraph1contract);

    var graph2_contractValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph2contract = cloneDeep(PricePointgraph2_contract);
    PricePointcloneGraph2contract.labels = labelsPricePoint;
    PricePointcloneGraph2contract.datasets[0].data = graph2_contractValPricePoint;
    setPricePointgraph2_contract(PricePointcloneGraph2contract);

    var graph3_contractValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph3contract = cloneDeep(PricePointgraph3_contract);
    PricePointcloneGraph3contract.labels = labelsPricePoint;
    PricePointcloneGraph3contract.datasets[0].data = graph3_contractValPricePoint;
    setPricePointgraph3_contract(PricePointcloneGraph3contract);

    var graph4_contractValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph4contract = cloneDeep(PricePointgraph4_contract);
    PricePointcloneGraph4contract.labels = labelsPricePoint;
    PricePointcloneGraph4contract.datasets[0].data = graph4_contractValPricePoint;
    setPricePointgraph4_contract(PricePointcloneGraph4contract);

    //new listings

    var graph1_listingValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph1?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph1Newlisting = cloneDeep(PricePointgraph1_listings);
    PricePointcloneGraph1Newlisting.labels = labelsPricePoint;
    PricePointcloneGraph1Newlisting.datasets[0].data = graph1_listingValPricePoint;
    setPricePointgraph1_listings(PricePointcloneGraph1Newlisting);

    var graph2_listingValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph2?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph2Newlisting = cloneDeep(PricePointgraph2_listings);
    PricePointcloneGraph2Newlisting.labels = labelsPricePoint;
    PricePointcloneGraph2Newlisting.datasets[0].data = graph2_listingValPricePoint;
    setPricePointgraph2_listings(PricePointcloneGraph2Newlisting);

    var graph3_listingValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph3?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph3Newlisting = cloneDeep(PricePointgraph3_listings);
    PricePointcloneGraph3Newlisting.labels = labelsPricePoint;
    PricePointcloneGraph3Newlisting.datasets[0].data = graph3_listingValPricePoint;
    setPricePointgraph3_listings(PricePointcloneGraph3Newlisting);

    var graph4_listingValPricePoint =
       marketMovementByPricePoint?.contracts_signed?.data?.chartData?.graph4?.data.data.map(
        (elem) => elem?.value
      );

    let PricePointcloneGraph4Newlisting = cloneDeep(PricePointgraph4_listings);
    PricePointcloneGraph4Newlisting.labels = labelsPricePoint;
    PricePointcloneGraph4Newlisting.datasets[0].data = graph4_listingValPricePoint;
    setPricePointgraph4_listings(PricePointcloneGraph4Newlisting);


    //supply setstate

    var labelsSupply =
     supply?.chartData?.graph1?.data.map(
      (elem) => {
        if (reportView === "weekly") {
          let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
            elem.end_date
          ).format("MMM-DD")}`;
          return leb;
        } else {
          return elem?.name;
        }
      }
    );

  var valueSupply =
     supply?.chartData?.graph1?.data.map(
      (elem) => elem?.value
    );
  // console.log(labelsSupply)
  // console.log(value)

  let SupplycloneGraph = cloneDeep(Supplygraph1);
  SupplycloneGraph.labels = labelsSupply;
  SupplycloneGraph.datasets[0].data = valueSupply;

  setSupplygraph1(SupplycloneGraph);

  //Graph2

  // var labelsGraph2 =
  //    marketMovementBySupply?.market_movement?.data?.chartData?.graph2?.data.map(
  //     (elem) => elem?.name
  //   );
  // console.log("labelsGraph2", labelsGraph2);
  var graph2In_contractValueSupply =
     supply?.chartData?.graph2?.data.map(
      (elem) => elem?.value['in_contract']
    );
  var graph2new_listingValueSupply =
     supply?.chartData?.graph2?.data.map(
      (elem) => elem?.value['new_listing']
    );
  var graph2saleValueSupply =
     supply?.chartData?.graph2?.data.map(
      (elem) => elem?.value['supply']
    );

  let SupplycloneGraph2 = cloneDeep(Supplygraph2);
  // SupplycloneGraph2.labels = labelsSupply;
  SupplycloneGraph2.datasets[0].data = graph2new_listingValueSupply;
  SupplycloneGraph2.datasets[1].data = graph2In_contractValueSupply;
  SupplycloneGraph2.datasets[2].data = graph2saleValueSupply;

  console.log("SupplycloneGraph2", SupplycloneGraph2);
  setSupplygraph2(SupplycloneGraph2);

  //grapg 3

  // var labelsGraph3Supply =
  //    supply?.chartData?.graph3?.data.data.map(
  //     (elem) => elem?.name
  //   );

  var graph3In_contractValueSupply =
     supply?.chartData?.graph3?.data.map(
      (elem) => elem?.value['in_contract']
    );
  var graph3new_listingValueSupply =
     supply?.chartData?.graph3?.data.map(
      (elem) => elem?.value['new_listing']
    );
  var graph3saleValueSupply =
     supply?.chartData?.graph3?.data.map(
      (elem) => elem?.value['supply']
    );

  let SupplycloneGraph3 = cloneDeep(Supplygraph3);
  // SupplycloneGraph3.labels = labelsGraph3Supply;
  SupplycloneGraph3.datasets[0].data = graph3new_listingValueSupply;
  SupplycloneGraph3.datasets[1].data = graph3In_contractValueSupply;
  SupplycloneGraph3.datasets[2].data = graph3saleValueSupply;

  console.log("cloneGraph2", SupplycloneGraph3);
  setSupplygraph3(SupplycloneGraph3);

//   //Graph4

  // var labelsGraph4Supply =
  //    supply?.chartData?.graph4?.data.data.map(
  //     (elem) => elem?.name
  //   );

  var graph4In_contractValueSupply =
     supply?.chartData?.graph4?.data.map(
      (elem) => elem?.value['new_listing']
    );
    console.log("graph4In_contractValueSupply",graph4In_contractValueSupply)
  var graph4new_listingValueSupply =
     supply?.chartData?.graph4?.data.map(
      (elem) => elem?.value['in_contract']
    );
  var graph4saleValueSupply =
     supply?.chartData?.graph4?.data.map(
      (elem) => elem?.value['supply']
    );

  let SupplycloneGraph4 = cloneDeep(Supplygraph4);
  // SupplycloneGraph4.labels = labelsGraph4Supply;
  SupplycloneGraph4.datasets[0].data = graph4new_listingValueSupply;
  SupplycloneGraph4.datasets[1].data = graph4In_contractValueSupply;
  SupplycloneGraph4.datasets[2].data = graph4saleValueSupply;

  // console.log("cloneGraph2", SupplycloneGraph4);
  setSupplygraph4(SupplycloneGraph4);



//   //graph 5 and 6

  var labelsSupply5 =
     supply?.chartData?.graph5.data.map(
      (elem) => {
        if (reportView === "weekly") {
          let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
            elem.end_date
          ).format("MMM-DD")}`;
          return leb;
        } else {
          return elem?.name;
        }
      }
    );

  var valueSupply5 =
     supply?.chartData?.graph5.data.map(
      (elem) => elem?.value
    );
  // console.log(labelsSupply5)
  // console.log(value)

  let SupplycloneGraph5 = cloneDeep(Supplygraph5);
  SupplycloneGraph5.labels = labelsSupply5;
  SupplycloneGraph5.datasets[0].data = valueSupply5;
console.log('SupplycloneGraph5',SupplycloneGraph5)
  setSupplygraph5(SupplycloneGraph5);

  var labelsSupply6 =
     supply?.chartData?.graph6.data.map(
      (elem) => {
        if (reportView === "weekly") {
          let leb = `${moment(elem.start_date).format("MMM-DD")} - ${moment(
            elem.end_date
          ).format("MMM-DD")}`;
          return leb;
        } else {
          return elem?.name;
        }
      }
    );

  var valueSupply6 =
     supply?.chartData?.graph6.data.map(
      (elem) => elem?.value
    );
  // console.log(labelsSupply6)
  // console.log(value)

  let SupplycloneGraph6 = cloneDeep(Supplygraph6);
  SupplycloneGraph6.labels = labelsSupply6;
  SupplycloneGraph6.datasets[0].data = valueSupply6;
  console.log('SupplycloneGraph6',SupplycloneGraph6)

  setSupplygraph6(SupplycloneGraph6);

  // graph 7 ,8

  // var labelsGraph7_supply =
  //   supply?.chartData?.graph7?.data.data.map(
  //   (elem) => console.log("mapppppp",elem)
  // );sidha state maj define

var graph7_supply_1 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['April 2022']
  );
var graph7_supply_2 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['March 2022']
  );
var graph7_supply_3 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['February 2022']
  );
  var graph7_supply_4 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['January 2022']
  );
  var graph7_supply_5 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['December 2021']
  );
  var graph7_supply_6 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['November 2021']
  );
  var graph7_supply_7 =
    supply?.chartData?.graph7.data.map(
    (elem) => elem?.value['October 2021']
  );
let cloneGraph7_supply = cloneDeep(Supplygraph7);
// cloneGraph7_supply.labels = labelsGraph7_supply;
cloneGraph7_supply.datasets[0].data = graph7_supply_1;
cloneGraph7_supply.datasets[1].data = graph7_supply_2;
cloneGraph7_supply.datasets[2].data = graph7_supply_3;
cloneGraph7_supply.datasets[3].data = graph7_supply_4;
cloneGraph7_supply.datasets[4].data = graph7_supply_5;
cloneGraph7_supply.datasets[5].data = graph7_supply_6;
cloneGraph7_supply.datasets[6].data = graph7_supply_7;

console.log("graph7_supply_1", graph7_supply_1);
setSupplygraph7(cloneGraph7_supply);

// graph 8
// var labelsGraph8_supply =
//     supply?.chartData?.graph8?.data.data.map(
//     (elem) => console.log("mapppppp",elem)
//   );

var graph8_supply_1 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['April 2022']
  );
var graph8_supply_2 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['March 2022']
  );
var graph8_supply_3 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['February 2022']
  );
  var graph8_supply_4 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['January 2022']
  );
  var graph8_supply_5 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['December 2021']
  );
  var graph8_supply_6 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['November 2021']
  );
  var graph8_supply_7 =
    supply?.chartData?.graph8.data.map(
    (elem) => elem?.value['October 2021']
  );
let cloneGraph8_supply = cloneDeep(Supplygraph8);
// cloneGraph8_supply.labels = labelsGraph8_supply;
cloneGraph8_supply.datasets[0].data = graph8_supply_1;
cloneGraph8_supply.datasets[1].data = graph8_supply_2;
cloneGraph8_supply.datasets[2].data = graph8_supply_3;
cloneGraph8_supply.datasets[3].data = graph8_supply_4;
cloneGraph8_supply.datasets[4].data = graph8_supply_5;
cloneGraph8_supply.datasets[5].data = graph8_supply_6;
cloneGraph8_supply.datasets[6].data = graph8_supply_7;

console.log("cloneGraph8_supply", cloneGraph8_supply);
setSupplygraph8(cloneGraph8_supply);

var graph9_supply_1 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['April 2022']
  );
var graph9_supply_2 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['March 2022']
  );
var graph9_supply_3 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['February 2022']
  );
  var graph9_supply_4 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['January 2022']
  );
  var graph9_supply_5 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['December 2021']
  );
  var graph9_supply_6 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['November 2021']
  );
  var graph9_supply_7 =
    supply?.chartData?.graph9?.data.map(
    (elem) => elem?.value['October 2021']
  );
let cloneGraph9_supply = cloneDeep(Supplygraph9);
// cloneGraph7_supply.labels = labelsGraph7_supply;
cloneGraph9_supply.datasets[0].data = graph9_supply_1;
cloneGraph9_supply.datasets[1].data = graph9_supply_2;
cloneGraph9_supply.datasets[2].data = graph9_supply_3;
cloneGraph9_supply.datasets[3].data = graph9_supply_4;
cloneGraph9_supply.datasets[4].data = graph9_supply_5;
cloneGraph9_supply.datasets[5].data = graph9_supply_6;
cloneGraph9_supply.datasets[6].data = graph9_supply_7;

setSupplygraph9(cloneGraph9_supply);


//graph 10

var graph10_supply_1 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['April 2022']
  );
var graph10_supply_2 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['March 2022']
  );
var graph10_supply_3 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['February 2022']
  );
  var graph10_supply_4 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['January 2022']
  );
  var graph10_supply_5 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['December 2021']
  );
  var graph10_supply_6 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['November 2021']
  );
  var graph10_supply_7 =
    supply?.chartData?.graph10?.data.map(
    (elem) => elem?.value['October 2021']
  );
let cloneGraph10_supply = cloneDeep(Supplygraph10);
// cloneGraph7_supply.labels = labelsGraph7_supply;
cloneGraph10_supply.datasets[0].data = graph10_supply_1;
cloneGraph10_supply.datasets[1].data = graph10_supply_2;
cloneGraph10_supply.datasets[2].data = graph10_supply_3;
cloneGraph10_supply.datasets[3].data = graph10_supply_4;
cloneGraph10_supply.datasets[4].data = graph10_supply_5;
cloneGraph10_supply.datasets[5].data = graph10_supply_6;
cloneGraph10_supply.datasets[6].data = graph10_supply_7;

setSupplygraph10(cloneGraph10_supply);

  }, [totalMarketTrends, marketMovementByLocation,marketMovementByPricePoint,supply]);

  return (
    <div className="container my-4 ">
      <div className="d-flex justify-content-between py-3">
        <div>
          <h5>Reports</h5>
        </div>
        <div className="d-flex">
          {showLocation && (
            <div className="border border-secondary p-2">
              <Dropdown
                overlay={
                  <Menu
                    onClick={async (val) => {
                      console.log("vall", val);
                      setLocationFilter(val.key);

                       dispatch(
                        getMarketMovementByLocation({
                          // graphType: filter_data.type,
                          location: val.key,
                          // graphType: reportView ? "weekly" : "monthly",
                        })
                      );
                    }}
                  >
                    <Menu.Item key="East Hampton">East Hampton</Menu.Item>
                    <Menu.Item key="Amagansett">Amagansett</Menu.Item>
                    <Menu.Item key="Southampton">Southampton</Menu.Item>
                    <Menu.Item key="Montauk">Montauk</Menu.Item>
                    <Menu.Item key="Bridgehampton">Bridgehampton</Menu.Item>
                    <Menu.Item key="Wainscott">Wainscott</Menu.Item>
                    <Menu.Item key="Sag Harbor">Sag Harbor</Menu.Item>
                  </Menu>
                }
              >
                <a
                  className="ant-dropdown-link text-secondary"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => e.preventDefault()}
                >
                  {LocationFilter ? LocationFilter : "Location"}{" "}
                  <DownOutlined />
                </a>
              </Dropdown>
            </div>
          )}

          {showPricePoint && (
            <div className="border border-secondary p-2">
              <Dropdown
                overlay={
                  <Menu
                    onClick={async (val) => {
                      console.log("pricepointDropdown", JSON.parse(val.key) );
                      setPricePointFilter(JSON.parse(val.key));

                      await dispatch(
                        getMarketMovementByPricePoint({
                          // graphType: filter_data.type,
                          range: JSON.parse(val.key),
                          // graphType: reportView ? "weekly" : "monthly",
                        })
                      );
                    }}
                  >
                    {/* <Menu.Item key="East Hampton">East Hampton</Menu.Item>
                  <Menu.Item key="Amagansett">Amagansett</Menu.Item>
                  <Menu.Item key="Southampton">Southampton</Menu.Item>
                  <Menu.Item key="Montauk">Montauk</Menu.Item>
                  <Menu.Item key="Bridgehampton">Bridgehampton</Menu.Item>
                  <Menu.Item key="Wainscott">Wainscott</Menu.Item>
                  <Menu.Item key="Sag Harbor">Sag Harbor</Menu.Item> */}
                    {pricePointData &&
                      pricePointData.map((elem) => {
                        console.log(elem);
                        let range={minValue:elem.minValue,
                          maxValue:elem.maxValue,
                        name:elem.name}
                        return <>
                        <Menu.Item key={JSON.stringify(range)}>{elem.name}</Menu.Item>
                        </>;
                      })}
                  </Menu>
                }
              >
                <a
                  className="ant-dropdown-link text-secondary"
                  style={{ textDecoration: "none" }}
                  onClick={(e) => e.preventDefault()}
                >
                  {PricePointFilter ? PricePointFilter?.name : "Price Point"}{" "}
                  <DownOutlined />
                </a>
              </Dropdown>
            </div>
          )}
{!supplyFilter && (
          <div className="border border-secondary p-2">
            <Dropdown
              overlay={
                <Menu
                  onClick={async (val) => {
                    console.log(val);
                    let filter_data = {
                      type: val?.keyPath[1],
                      duration: parseInt(val?.keyPath[0]),
                    };
                    setDurationLabel(filter_data);
                    console.log("filter_data", filter_data);
                    var start_date = null;
                    if (filter_data.type === "weekly") {
                      var start_date = moment(endDate).subtract(
                        parseInt(filter_data.duration),
                        "weeks"
                      );
                      console.log("start_date", start_date);
                      setStartDate(start_date)
                      setreportView("weekly");
                    } else {
                      var start_date = moment(endDate).subtract(
                        parseInt(filter_data.duration),
                        "months"
                      );
                      console.log("start_date", start_date);
                      setStartDate(start_date)
                      setreportView("monthly");
                    }

                    // await dispatch(
                    //   getTotalMarketTrends({
                    //     startDate: start_date,
                    //     endDate: endDate,
                    //     graphType: filter_data.type,
                    //     // graphType: reportView ? "weekly" : "monthly",
                    //   })
                    // );
                  }}
                >
                  {!supplyFilter && <><SubMenu key="weekly" title="Weekly">
                    <Menu.Item key="12">12 Weeks</Menu.Item>

                    <Menu.Item key="26">26 Weeks</Menu.Item>
                  </SubMenu>
                  </>}
                  <SubMenu key="monthly" title="Monthly">
                    <Menu.Item key="2">2 Months</Menu.Item>
                    <Menu.Item key="6">6 Months</Menu.Item>
                    <Menu.Item key="12">12 Months</Menu.Item>
                  </SubMenu>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link text-secondary"
                style={{ textDecoration: "none" }}
                onClick={(e) => e.preventDefault()}
              >
                {DurationLabel
                  ? DurationLabel.duration +
                    " " +
                    DurationLabel?.type.slice(0, -2) +
                    " "
                  : "Weekly/Monthly"}

                <DownOutlined />
              </a>
            </Dropdown>
          </div>
)}
        </div>
      </div>
      <div className="my-3">
        <Tabs
          defaultActiveKey="1"
          onChange={(activeTab) => {
            console.log("activeTab", activeTab);
            handleTabChange(activeTab);
          }}
          onTabClick={(r) => console.log("ads", r)}
        >
          <>
            <TabPane tab="Total Market Trends" key="1">
              {!loading1 ? (
                <>
                  <div className="py-3 d-flex flex-column">
                    <h6>Market Movement</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.market_movement?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.market_movement?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {totalMarketTrends?.market_movement?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {totalMarketTrends?.market_movement?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>The hamptons-Listing Supply 2021</h6>
                              </p>
                              <div className="bar-chart">
                                <Bar
                                  data={graph1}
                                  options={{
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },
                                    maintainAspectRatio: false,

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 20,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Number of Listings</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph2}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Price Point</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Bar
                                    data={graph4}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      responsive: true,
                                      interaction: {
                                        mode: "index",
                                        intersect: true,
                                      },
                                      scales: {
                                        x: {
                                          stacked: true,
                                        },
                                        y: {
                                          stacked: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Areas</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Bar
                                  data={graph3}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    interaction: {
                                      mode: "index",
                                      intersect: true,
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                      },
                                      y: {
                                        stacked: true,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>Sold Listing</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.days_on_market?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Days on Marklet</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.sold_listings?.data?.calculationsData?.listing_discount?.data.toLocaleString(
                              "en-US"
                            )}
                            %
                          </h4>
                        </p>
                        <label>
                          <b>Listings Discount</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph1_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph2_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph3_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Medium Price</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph4_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Discount</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph5_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph6_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>Contracts Signed</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.contracts_signed?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.contracts_signed?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.contracts_signed?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average List Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph1_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph2_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph3_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph4_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>New Listings</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.new_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.new_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium List Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.new_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {totalMarketTrends?.new_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph1_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph2_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={graph3_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={graph4_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </>
              ) : (
                <div className="example">
                  <Spin className="spin" />
                </div>
              )}
            </TabPane>

            <TabPane tab="Market Movement By Location" key="2">
              {!loading2 ? (
                <>
                  {/* <div className="py-3 d-flex flex-column">
                    <h6>Market Movement</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.market_movement?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.market_movement?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByLocation?.market_movement?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByLocation?.market_movement?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>The hamptons-Listing Supply 2021</h6>
                              </p>
                              <div className="bar-chart">
                                <Bar
                                  data={Locationgraph1}
                                  options={{
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },
                                    maintainAspectRatio: false,

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 20,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Number of Listings</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph2}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Price Point</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Bar
                                    data={Locationgraph4}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      responsive: true,
                                      interaction: {
                                        mode: "index",
                                        intersect: true,
                                      },
                                      scales: {
                                        x: {
                                          stacked: true,
                                        },
                                        y: {
                                          stacked: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Areas</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Bar
                                  data={Locationgraph3}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    interaction: {
                                      mode: "index",
                                      intersect: true,
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                      },
                                      y: {
                                        stacked: true,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div> */}

                  <div className="py-3 d-flex flex-column">
                    <h6>Sold Listing</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.days_on_market?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Days on Marklet</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.sold_listings?.data?.calculationsData?.listing_discount?.data.toLocaleString(
                              "en-US"
                            )}
                            %
                          </h4>
                        </p>
                        <label>
                          <b>Listings Discount</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph1_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph2_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph3_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Medium Price</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph4_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Discount</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph5_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph6_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>Contracts Signed</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.contracts_signed?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.contracts_signed?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.contracts_signed?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average List Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph1_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph2_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph3_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph4_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>New Listings</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.new_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.new_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium List Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.new_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByLocation?.new_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph1_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph2_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={Locationgraph3_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={Locationgraph4_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </>
              ) : (
                <div className="example">
                  <Spin className="spin" />
                </div>
              )}
            </TabPane>

            <TabPane tab="Market Movement By Price Point" key="3">
            {!loading3 ? (
                <>
                  {/* <div className="py-3 d-flex flex-column">
                    <h6>Market Movement</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.market_movement?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.market_movement?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByPricePoint?.market_movement?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByPricePoint?.market_movement?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>The hamptons-Listing Supply 2021</h6>
                              </p>
                              <div className="bar-chart">
                                <Bar
                                  data={PricePointgraph1}
                                  options={{
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },
                                    maintainAspectRatio: false,

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 20,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Number of Listings</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph2}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Price Point</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Bar
                                    data={PricePointgraph4}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      responsive: true,
                                      interaction: {
                                        mode: "index",
                                        intersect: true,
                                      },
                                      scales: {
                                        x: {
                                          stacked: true,
                                        },
                                        y: {
                                          stacked: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Areas</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Bar
                                  data={PricePointgraph3}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    interaction: {
                                      mode: "index",
                                      intersect: true,
                                    },
                                    scales: {
                                      x: {
                                        stacked: true,
                                      },
                                      y: {
                                        stacked: true,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div> */}

                  <div className="py-3 d-flex flex-column">
                    <h6>Sold Listing</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            ${" "}
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.days_on_market?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Days on Marklet</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.sold_listings?.data?.calculationsData?.listing_discount?.data.toLocaleString(
                              "en-US"
                            )}
                            %
                          </h4>
                        </p>
                        <label>
                          <b>Listings Discount</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        accordion
                        expandIconPosition="right"
                        className="chart-collapse p-0"
                        onChange={(d) => {
                          handleCollapse("collapse1", d);
                        }}
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph1_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph2_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph3_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Medium Price</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph4_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Discount</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph5_SoldListing}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph6_SoldListing}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>Contracts Signed</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.contracts_signed?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.contracts_signed?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.contracts_signed?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average List Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph1_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph2_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph3_contract}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph4_contract}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>

                  <div className="py-3 d-flex flex-column">
                    <h6>New Listings</h6>
                    <div className="card-grid">
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.new_listings?.data?.calculationsData?.no_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Number of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.new_listings?.data?.calculationsData?.median_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Medium List Price</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.new_listings?.data?.calculationsData?.volume_of_listings?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Volume of Sold Listing</b>
                        </label>
                      </Card.Grid>
                      <Card.Grid>
                        <p>
                          <h4>
                            {marketMovementByPricePoint?.new_listings?.data?.calculationsData?.average_list_price?.data.toLocaleString(
                              "en-US"
                            )}
                          </h4>
                        </p>
                        <label>
                          <b>Average Price</b>
                        </label>
                      </Card.Grid>
                    </div>
                    <div className="my-3">
                      <Collapse
                        expandIconPosition="right"
                        className="chart-collapse"
                      >
                        <Panel
                          header={
                            <div
                              className={
                                collapseToggle.collapseActiveKey1
                                  ? "panel-header"
                                  : ""
                              }
                            >
                              <span className="px-3">View Chart</span>
                            </div>
                          }
                          className="p-0"
                          key="1"
                        >
                          <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Number of Listings</h6>
                              </p>
                              <div className="bar-chart">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph1_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Listings Volumes</h6>
                                  <p> September 2020 - September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph2_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Average Price</h6>
                                  <p>September 2021</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Line
                                    datasetKeyProvider="label"
                                    data={PricePointgraph3_listings}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Days on Market</h6>
                              </p>
                              <p>September 2021</p>
                              <div className="bar-chart mt-5">
                                <Line
                                  datasetKeyProvider="label"
                                  data={PricePointgraph4_listings}
                                  // height={"100px"}
                                  options={{
                                    elements: {
                                      point: {
                                        radius: 5,
                                      },
                                    },
                                    maintainAspectRatio: false,
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 2,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </>
              ) : (
                <div className="example">
                  <Spin className="spin" />
                </div>
              )}
            </TabPane>

            <TabPane tab="Supply" key="4">
            {!loading4 ? (
                <>
            <div className="main-collapse-div">
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>The hamptons-Listing Supply</h6>
                                <p> August 2021 - March 2020</p>
                              </p>
                              <div className="bar-chart">
                                <Bar
                                  data={Supplygraph1}
                                  options={{
                                    scales: {
                                      x: {
                                        beginAtZero: true,
                                        grid: {
                                          display: false,
                                        },
                                      },
                                    },
                                    maintainAspectRatio: false,

                                    title: {
                                      display: true,
                                      text: "Average Salse per month",
                                      fontSize: 20,
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Supply Movement | The Hamptons</h6>
                                  <p> August 2021 - March 2020</p>
                                </p>
                                <div className="mt-5 chart">
                                <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph2}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Supply Movement | Price Points</h6>
                                  <p> August 2021 - March 2020</p>
                                </p>
                                <div className="mt-5 chart">
                                <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph3}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="bar-chart-div shadow-div">
                              <p className="m-0">
                                <h6 className="m-0">Supply Movement | Areas</h6>
                              </p>
                              <p> August 2021 - March 2020</p>
                              <div className="bar-chart mt-5">
                              <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph4}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                              </div>
                            </div>


{/* //graph 5 and 6 hori.. */}

<div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">The Hamptons | Absorption Rate</h6>
                                  <p> Month to Month</p>
                                </p>
                                <div className="mt-5 chart">
                                <Bar
                                    data={Supplygraph5}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      responsive: true,
                                      interaction: {
                                        mode: "index",
                                        intersect: true,
                                      },
                                      scales: {
                                        x: {
                                          stacked: true,
                                        },
                                        y: {
                                          stacked: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">The Hamptons | Odds Of Selling</h6>
                                  <p>Month to Month</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Bar
                                    data={Supplygraph6}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      responsive: true,
                                      interaction: {
                                        mode: "index",
                                        intersect: true,
                                      },
                                      scales: {
                                        x: {
                                          stacked: true,
                                        },
                                        y: {
                                          stacked: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* grpah 7 and 8 */}

                            <div className="middle-chart-div d-lg-flex d-sm-inline justify-content-between">
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Absorption Rate by Price Point</h6>
                                  <p> Month to Month</p>
                                </p>
                                <div className="mt-5 chart">
                                  <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph7}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="middle-chart shadow-div">
                                <p className="m-0">
                                  <h6 className="m-0">Odds Of Selling by Price Point</h6>
                                  <p>Month to Month</p>
                                </p>
                                <div className="mt-5 chart">
                                <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph8}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* graph 9 */}
                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Absorption Rate by Area</h6>
                              </p>
                              <div className="bar-chart">
                              <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph9}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                              </div>
                            </div>

                            {/* graph 10 supply */}

                            <div className="bar-chart-div shadow-div">
                              <p>
                                <h6>Odds Of Selling by Area</h6>
                              </p>
                              <div className="bar-chart">
                              <Bar
                                    datasetKeyProvider="label"
                                    data={Supplygraph10}
                                    // height={"100px"}
                                    options={{
                                      elements: {
                                        point: {
                                          radius: 5,
                                        },
                                      },
                                      maintainAspectRatio: false,
                                      scales: {
                                        x: {
                                          beginAtZero: true,
                                          grid: {
                                            display: false,
                                          },
                                        },
                                      },

                                      title: {
                                        display: true,
                                        text: "Average Salse per month",
                                        fontSize: 2,
                                      },
                                      plugins: {
                                        legend: {
                                          display: true,
                                        },
                                      },
                                    }}
                                  />
                              </div>
                            </div>


                          </div>
                          </>
              ) : (
                <div className="example">
                  <Spin className="spin" />
                </div>
              )}
              </TabPane>
          </>
          {/* ) : (
            <div className="example">
              <Spin className="spin" />
            </div>
          )} */}
        </Tabs>
      </div>
    </div>
  );
};

export default Reports;
