import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../utils/api";
import {supply as tempData} from '../../Components/temp_data'

const initialState = {
  totalMarketTrends: [],
  marketMovementByLocation:[],
  marketMovementByPricePoint:[],
  supply:[],
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false,
};

export const getTotalMarketTrends = createAsyncThunk(
  "HMD/getTotalMarketTrends",

  async (body, thunkAPI) => {
    console.log("body", body);
    let bodyData = {
      filters: {
        date: {
          greater_than_equal: body.startDate || "2021-12-12T06:33:51.176Z",
          lesser_than_equal: body.endDate || "2022-02-17 06:00:00.000Z",
        },
      },
      type: "total_market_trends",
      graphs: {
        type: body.graphType || "weekly",
      },
    };

    const response = await ApiCall("POST",bodyData,"/api/property/reports",
      ""
    ).then((res) => res);
    console.log("response", response.data.data);

    return response.data.data;
  }
);

export const getMarketMovementByLocation= createAsyncThunk(
  "HMD/getMarketMovementByLocation",

  async (body, thunkAPI) => {
    console.log("body", body);
    let bodyData = {
      filters: {
        date: {
          greater_than_equal: body.startDate || "2021-12-12T06:33:51.176Z",
          lesser_than_equal: body.endDate || "2022-02-17 06:00:00.000Z",
        },
        location:body.location || 'Sagaponack'||'Wainscott'
      },
      type: "market_movement_by_location",
      graphs: {
        type: body.graphType || "weekly",
      },
    };

    const response = await ApiCall("POST",bodyData,"/api/property/reports",
      ""
    ).then((res) => res);
    console.log("response", response.data.data);

    return response.data.data;
  }
);

export const getMarketMovementByPricePoint= createAsyncThunk(
  "HMD/getMarketMovementByPricePoint",

  async (body, thunkAPI) => {
    console.log("body Pricepoint", body);
    let bodyData = {
      filters: {
        date: {
          greater_than_equal: body.startDate || "2021-12-12T06:33:51.176Z",
          lesser_than_equal: body.endDate || "2022-02-17 06:00:00.000Z",
        },
        priceRange :{
          minValue:body.range?.minValue || 10,
          maxValue:body.range?.maxValue || 100000000,
      }
      },
      type: "market_movement_by_price_point",
      graphs: {
        type: body.graphType || "weekly",
      },
    };

    const response = await ApiCall("POST",bodyData,"/api/property/reports",
      ""
    ).then((res) => res);
    console.log("response", response.data.data);

    return response.data.data;
  }
);

export const getSupply= createAsyncThunk(
  "HMD/getSupply",

  async (body, thunkAPI) => {
    console.log("body Pricepoint", body);
    let bodyData = {
     filters:{
         date:{
          // greater_than_equal: body.startDate || "2021-12-12T06:33:51.176Z",
          // lesser_than_equal: body.endDate || "2022-02-17 06:00:00.000Z",
          greater_than_equal:  "2021-12-12T06:33:51.176Z",
          lesser_than_equal: "2022-09-17 06:00:00.000Z",
          }
      },
     type:"supply",
     graphs:{
         type: "monthly"
      }
  }

    const response = await ApiCall("POST",bodyData,"/api/property/reports",
      ""
    ).then((res) => res);
    console.log("response", response.data.data);

    return response.data.data;
  }
);

export const reportSlice = createSlice({
  name: "HMD",
  initialState,
  reducers: {},
  extraReducers: {
    [getTotalMarketTrends.pending]: (state) => {
      state.loading1 = true;
      state.totalMarketTrends = [];
    },
    [getTotalMarketTrends.fulfilled]: (state, { payload }) => {
      state.loading1 = false;
      state.totalMarketTrends = payload;
    },
    [getTotalMarketTrends.rejected]: (state, { payload }) => {
      state.loading1 = false;
      state.totalMarketTrends = [];
    },

    [getMarketMovementByLocation.pending]: (state) => {
      state.loading2 = true;
      state.marketMovementByLocation = [];
    },
    [getMarketMovementByLocation.fulfilled]: (state, { payload }) => {
      state.loading2 = false;
      state.marketMovementByLocation = payload;
    },
    [getMarketMovementByLocation.rejected]: (state, { payload }) => {
      state.loading2 = false;
      state.marketMovementByLocation = [];
    },

    [getMarketMovementByPricePoint.pending]: (state) => {
      state.loading3 = true;
      state.marketMovementByPricePoint = [];
    },
    [getMarketMovementByPricePoint.fulfilled]: (state, { payload }) => {
      state.loading3 = false;
      state.marketMovementByPricePoint = payload;
    },
    [getMarketMovementByPricePoint.rejected]: (state, { payload }) => {
      state.loading3 = false;
      state.marketMovementByPricePoint = [];
    },


    [getSupply.pending]: (state) => {
      state.loading4 = true;
      state.supply = [];
    },
    [getSupply.fulfilled]: (state, { payload }) => {
      console.log('payloadsu', payload)
      state.loading4 = false;
      state.supply = payload;
    },
    [getSupply.rejected]: (state, { payload }) => {
      state.loading4 = false;
      state.supply = [];
    },

  },
});

export const reportReducer = reportSlice.reducer;
