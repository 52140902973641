import React from 'react'

const Header = () => {
    return (
        <div className='' style={{height:"70px",backgroundColor:"black"}}>
            
        </div>
    )
}

export default Header
