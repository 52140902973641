import React, { useState,useEffect } from "react";
import {
  //   Col,
  //   Row,
  Navbar,
  Nav,
  NavDropdown,
  NavItem,
  Container,
  Card,
  InputGroup,
} from "react-bootstrap";
import {
  Menu,
  Dropdown,
  Collapse,
  Tabs,
  Modal,
  Row,
  Col,
  Radio,
  Spin,
  Space,
} from "antd";
import {
  BarChartOutlined,
  ArrowRightOutlined,
  LineChartOutlined,
  DownloadOutlined,
  DownOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { Line, Bar } from "react-chartjs-2";
import graphIcon from "../Assets/graph-icon.png";
import "../Assets/css/home.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {getlocationData} from '../redux/market_location_slice/location'
import {getPricePointData} from '../redux/markey_pricepoint_slice/pricepoint'
import { getGraph } from "../redux/Graph_slice/graph";
import { cloneDeep } from "lodash";
import moment from 'moment'

const Home = () => {
  const [chartType, setchartType] = useState(true);
  const [downloadModal, setdownloadModal] = useState(false);
  const [ShowChart, setShowChart] = useState(false);
  
  // state for sidebar Filter
  const [MarketLocation, setMarketLocation] = useState({});
  const [PricePoint, setPricePoint] = useState({});

  // heading state
  const [StartDurationMonthly, setStartDurationMonthly] = useState();
  const [EndDurationMonthly, setEndDurationMonthly] = useState();

  const [StartDurationWeekly, setStartDurationWeekly] = useState();
  const [EndDurationWeekly, setEndDurationWeekly] = useState();

  // listing types
  const [ListingType, setListingType] = useState();

const [GraphLabels, setGraphLabels] = useState([]);
  // state for graph
  // const [LineChart, setLineChart] = useState({});
  const [BarChart, setBarChart] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: chartType === false && "rgba(0,0,0,1)",
        borderWidth: chartType === false && 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  })

  const [LineChart, setLineChart] = useState({
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets:  [
      {
        label: false,
        // fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor:  "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  })


  const [graphDataObj, setgraphDataObj] = useState({
   x_axis:{
       data_point_type:"monthly",
       sub_data_point_type:12
    },
   y_axis:{
       listing_type:"New Listing",  
       data_value_type:"no_of_listings"
    }
    ,
   other_filters:{
        
           location:"East Hampton",
           priceRange :{
               minValue:10,
               maxValue:3690010000
            }
    }
    });
// console.log(graphDataObj)

  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(getlocationData())
    dispatch(getPricePointData())
  },[])

  useEffect(()=>{
    if(graphDataObj.x_axis && graphDataObj.y_axis){
      let listing_typesmap={
        'Contract Signed':'in_contract',
        'New Listing':'new_listing',
        'Sold Listing':'sale'
      }
      // const temp={...graphDataObj.y_axis}

      let graphDataClone=cloneDeep(graphDataObj)

      graphDataClone.y_axis.listing_type=listing_typesmap[graphDataObj.y_axis.listing_type]
      // graphDataObj.y_axis.listing_type=listing_typesmap[graphDataObj.y_axis.listing_type]

      console.log('graphDataObj',graphDataObj)
      // console.log('temp',temp)
      // setgraphDataObj(graphDataObj)
      dispatch(getGraph(graphDataClone))
    }
  },[graphDataObj])

  // moment.locale('en-Au');

  
  const {locationData} = useSelector(state => state?.Location)
  // console.log('locationData', locationData)
  const {pricePointData} = useSelector(state => state?.PricePoint)
  // console.log('pricePointData', pricePointData)
  const {graphData,loading} = useSelector(state => state?.Graph)
  // console.log('graphData', graphData)
  
  useEffect(async ()=>{
    if(graphData !== undefined){

      let data=await graphData.map((elem)=>elem?.value)
      
      
      // let labels=[]
      if(graphDataObj.x_axis.data_point_type==="weekly"){
        
        var labels=await graphData.map((elem)=>{
          // console.log(moment(elem.start_date).format('MMM-D'))
          let leb=`${moment(elem.start_date).format('MMM-DD')} - ${moment(elem.end_date).format('MMM-DD')}`
          return leb
        })
        console.log("weel;y",graphData)
        let start=`${moment(graphData[0].start_date).format('DD MMM YYYY')}`
        let lastDurationWeek=graphData[graphData.length-1]

        let end=`${moment(lastDurationWeek.end_date).format('DD MMM YYYY')}`
        setStartDurationWeekly(start)
        setEndDurationWeekly(end)

      }else{
        var labels=await graphData.map((elem)=>elem?.name)
        setStartDurationMonthly(graphData[0].name)
        let lastDuration=graphData[graphData.length-1]
        setEndDurationMonthly(lastDuration.name)
        
        }


      let cloneDataBar=cloneDeep(BarChart)
      cloneDataBar.labels=labels
      cloneDataBar.datasets[0].data=data

      setBarChart(cloneDataBar)

      let cloneDataLine=cloneDeep(LineChart)
      cloneDataLine.labels=labels
      cloneDataLine.datasets[0].data=data

      // setBarChart(cloneDataBar)
      setLineChart(cloneDataLine)

      // console.log(graphData[0].name)
     
     
    }
  },[graphData])
  // console.log(BarChart)

  
  const { Panel } = Collapse;

  const handle = (params) => {
    console.log("object");
  };

  const { SubMenu } = Menu;

  const menu = (
    <Menu onClick={(val)=>{
      console.log(val)
      let x_axis={
        data_point_type:val?.keyPath[1],
        sub_data_point_type:parseInt(val?.keyPath[0])
      }
      console.log('x_axis', x_axis)
     setgraphDataObj((prev)=>({
      ...prev,
      x_axis:x_axis
    }))
    }}>
      <SubMenu key="weekly" title="Weekly">
        <Menu.Item key="12">12 Weeks</Menu.Item>
        <Menu.Item key="26">26 Weeks</Menu.Item>
        <Menu.Item key="53">53 Weeks</Menu.Item>
      </SubMenu>
      <SubMenu key="monthly" title="Monthly">
        <Menu.Item key="2" >2 Months</Menu.Item>
        <Menu.Item key="6" >6 Months</Menu.Item>
        <Menu.Item key="12" >12 Months</Menu.Item>
      </SubMenu>
    </Menu>
  );

  const menu2 = (
    <Menu onClick={(val)=>{
      console.log(val)
     let y_axis={
        listing_type:val?.keyPath[1],  
        data_value_type:val?.keyPath[0]
     }
     setgraphDataObj((prev)=>({
      ...prev,
      y_axis:y_axis
    }))
      }}>
      {/* <SubMenu key="new_listing" title="New Listing"> */}
      <SubMenu key="New Listing" title="New Listing">
        <Menu.Item key="no_of_listings" >Number of Listing</Menu.Item>
        <Menu.Item key="listing_volume" >Listing Volume</Menu.Item>
        <Menu.Item key="average_list_price" >Average List Price</Menu.Item>
        <Menu.Item key="median_list_price" >Medium List Price</Menu.Item>
      </SubMenu>
      {/* <SubMenu key="in_contract" title="Contract Signed"> */}
      <SubMenu key="Contract Signed" title="Contract Signed">
        <Menu.Item key="no_of_listings" >Number of Listing</Menu.Item>
        <Menu.Item key="listing_volume" >Listing Volume</Menu.Item>
        <Menu.Item key="average_list_price" >Average List Price</Menu.Item>
        <Menu.Item key="median_list_price" >Medium List Price</Menu.Item>
      </SubMenu>
      {/* <SubMenu key="sale" title="Sold Listing"> */}
      <SubMenu key="Sold Listing" title="Sold Listing">
        <Menu.Item key="no_of_listings" >Number of Listing</Menu.Item>
        <Menu.Item key="listing_volume" >Listing Volume</Menu.Item>
        <Menu.Item key="average_list_price" >Average List Price</Menu.Item>
        <Menu.Item key="median_list_price" >Medium List Price</Menu.Item>
      </SubMenu>
    </Menu>
  );

  function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  const chartBar = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166", "#0A648D", "#408399", "#84C5DD"],
        borderColor: chartType === false && "rgba(0,0,0,1)",
        borderWidth: chartType === false && 2,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };

  const chartLine = {
    labels: [
      "Sep 20",
      "Oct 20",
      "Nov 20",
      "Dec 20",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 2021",
      "June 2021",
      "July 2021",
      "Aug 2021",
      "Sep 2021",
    ],
    datasets: [
      {
        label: false,
        fill: false,
        lineTension: 0.5,
        backgroundColor: ["#005166"],
        borderColor: chartType === false && "rgba(0,0,0,1)",
        borderWidth: 1,
        data: [565, 259, 380, 481, 156, 255, 344, 100, 299, 467, 366, 234, 532],
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "#f1f1f1" }}>
      <Container
        // fluid
        className="layout-container"
        // style={{ minHeight: "95vh" }}
      >
        <Row gutter={25} className="antd-roww">
          <Col lg={7} sm={24} className="col-filter">
            <div className="collapse-wrapper">
              <div
                className="filter-header"
                style={{ backgroundColor: "#0E5D72" }}
              >
                Filter By
              </div>
              <div
                className="total-market-trends"
                style={{ color: "black" }}
                onClick={()=>{setShowChart(true)
                setgraphDataObj((prev)=>({
                  ...prev,
                  other_filters:{pricePointData:{},location:""}
                }))}
                }
              >
                <p className="m-0 pl-2 ">Total Market Trends</p>
              </div>

              <Collapse accordion expandIconPosition="right" >
                <Panel header="Market Movement By Location" key="1">
                  <Radio.Group
                    onChange={(e) => {
                      if (e.target.value) {
                        console.log('e.target.value', e.target.value)
                        setShowChart(true);
                        let filterLocation={

                        }
                        setgraphDataObj((prev)=>({
                          ...prev,
                          other_filters:{...prev.other_filters,location:e.target.value}
                        }))
                      }
                    }}
                    className="mx-3"
                  >
                    <Space direction="vertical" size={"middle"}>
                      {locationData && locationData.map((elem,index)=>{
                        // console.log('elem', elem)
                        return (
                          <Radio value={elem?.name}>{elem?.name}</Radio>    
                        )
                      })}
                      {/* <Radio value={7}>Amangansett</Radio>
                      <Radio value={1}>Bridgehampton</Radio>
                      <Radio value={2}>East Hampton</Radio>
                      <Radio value={3}>Montuak</Radio>
                      <Radio value={4}>Sag Harbor</Radio>
                      <Radio value={5}>Sagaponack</Radio>
                      <Radio value={6}>South Hampton</Radio> */}
                    </Space>
                  </Radio.Group>
                </Panel>
                <Panel header="Market Movement By Price Point" key="2">
                  <Radio.Group
                    onChange={(e) => {
                      console.log(e.target.value);
                      const {value}=e.target
                      let range={
                        minValue:value.minValue,
                        maxValue:value.maxValue
                      }
                      setgraphDataObj((prev)=>({
                        ...prev,
                        other_filters:{...prev.other_filters,priceRange:range}
                      }))
                    }}
                    className="mx-3"
                  >
                    <Space direction="vertical" size={"middle"}>
                    {pricePointData && pricePointData.map((elem,index)=>{
                        // console.log('elem', elem)
                        return (
                          <Radio value={elem}>{elem?.name}</Radio>    
                        )
                      })}
                      {/* <Radio value={7}>Amangansett</Radio>
                      <Radio value={1}>Bridgehampton</Radio>
                      <Radio value={2}>East Hampton</Radio>
                      <Radio value={3}>Montuak</Radio>
                      <Radio value={4}>Sag Harbor</Radio>
                      <Radio value={5}>Sagaponack</Radio>
                      <Radio value={6}>South Hampton</Radio> */}
                    </Space>
                  </Radio.Group>
                </Panel>
                {/* <Panel header="Reports Coming Soon" key="3" >
                  <Radio.Group onChange={(e)=>{console.log(e.target.value)}} className="mx-3">
                    <Space direction="vertical" size={'middle'}>
                      <Radio value={7}>Amangansett</Radio>
                      <Radio value={1}>Bridgehampton</Radio>
                      <Radio value={2}>East Hampton</Radio>
                      <Radio value={3}>Montuak</Radio>
                      <Radio value={4}>Sag Harbor</Radio>
                      <Radio value={5}>Sagaponack</Radio>
                      <Radio value={6}>South Hampton</Radio>
                    </Space>
                  </Radio.Group>
                </Panel> */}
                {/* <div
                  className="py-2  d-flex justify-content-between"
                  style={{ color: "black" }}
                >
                  <Link to={'/reports'} style={{color:'black' ,textDecoration:'none'}}>
                  <span className="m-0 ">Reports</span>
                  </Link>
                </div> */}

               
              </Collapse>
 <div
                className="total-market-trends"
                style={{ color: "black" }}
                
              >
                {/* <p className="m-0 pl-2 ">Total Market Trends</p> */}
                <Link to={'/reports'} style={{color:'black' ,textDecoration:'none'}}>
                  <p className="m-0 ">Reports</p>
                  </Link>
              </div>
              {/* <Menu style={{ width: "auto" }} mode="inline">
                  <Menu.Item key="12">Total Market Trends</Menu.Item>
                  <SubMenu key="sub1" title="Market Movement By Location">
                    <Menu.Item className="side-item" onClick={()=>{console.log("object")}} key="1">
                      <input
                        type="radio"
                        className="mx-2 my-0"
                        name="rdo1"
                        onChange={() => setShowChart(true)}
                      />4    
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="2">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>East Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="3">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Montuak</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="4">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sag Harbor</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="5">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sagaponack</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="6">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>South Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="7">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="Market Movement By Price Point">
                    <Menu.Item className="side-item" key="8">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Amangansett</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="9">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="10">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>East Hampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="11">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Montuak</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="12">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Sagaponack</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="13">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                    <Menu.Item className="side-item" key="14">
                      <input type="radio" className="mx-2 my-0" name="rdo1" />
                      <label>Bridgehampton</label>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="sub3" title="Reports (Coming Soon)">
                  Reports (Coming Soon)
                  </Menu.Item>
                </Menu> */}
            </div>
          </Col>
          <Col lg={17} sm={24} className="col-chart">
            {ShowChart === true ? (
              <>
                <div className="col-2-wrapper">
                  <div className="chart-header d-lg-flex justify-content-between px-3">
                    <div className="py-2 week-web">
                      <div className="chart-dropdown1 d-block d-md-inline d-lg-inline border border-secondary p-2">
                        <Dropdown overlay={menu}>
                          <a
                            className="ant-dropdown-link text-secondary"
                            onClick={(e) => e.preventDefault()}
                          >
                            Weekly/Monthly <DownOutlined />
                          </a>
                        </Dropdown>
                      </div>
                      <div className="chart-dropdown2 d-block d-md-inline d-lg-inline border border-secondary p-2 ">
                        <Dropdown overlay={menu2}>
                          <a
                            className="ant-dropdown-link text-secondary"
                            onClick={(e) => e.preventDefault()}
                          >
                            Types of Listing <DownOutlined />
                          </a>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="py-2 week-mob">
                      <div className="chart-dropdown1 d-block d-md-inline d-lg-inline border  ">
                        <Menu
                          style={{ width: "auto" }}
                          // openKeys={this.openKeys}
                          // onOpenChange={this.onOpenChange}
                          mode="inline"
                        >
                          <SubMenu key="sub2" title="Weekly/Monthly">
                            <SubMenu key="sub3" title="Weekly">
                              <Menu.Item key="1">Option 7</Menu.Item>
                              <Menu.Item key="2">Option 8</Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub4" title="Monthly">
                              <Menu.Item key="3">Option 7</Menu.Item>
                              <Menu.Item key="4">Option 8</Menu.Item>
                            </SubMenu>
                          </SubMenu>
                        </Menu>
                      </div>
                      <div className="chart-dropdown2 d-block d-md-inline d-lg-inline border   ">
                        <Menu
                          style={{ width: "auto" }}
                          // openKeys={this.openKeys}
                          // onOpenChange={this.onOpenChange}
                          mode="inline"
                        >
                          <SubMenu key="sub2" title="Types of Listings">
                            <SubMenu key="sub3" title="Weekly">
                              <Menu.Item key="1">Option 7</Menu.Item>
                              <Menu.Item key="2">Option 8</Menu.Item>
                            </SubMenu>
                            <SubMenu key="sub4" title="Monthly">
                              <Menu.Item key="3">Option 7</Menu.Item>
                              <Menu.Item key="4">Option 8</Menu.Item>
                            </SubMenu>
                          </SubMenu>
                        </Menu>
                      </div>
                    </div>
                    <div className="chart-btn-div">
                      <button
                        className={
                          chartType === true ? "btn-chart-1" : "btn-chart-2"
                        }
                        onClick={() => {
                          setchartType(true);
                        }}
                      >
                        <BarChartOutlined style={{ fontSize: "20px" }} />
                      </button>
                      <button
                        className={
                          chartType === false ? "btn-chart-1" : "btn-chart-2"
                        }
                        onClick={() => {
                          setchartType(false);
                        }}
                      >
                        <LineChartOutlined style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </div>


                   {!loading ? (
                  
                  <>
                  <div className="date-header p-3 my-2">
                    <div className=" d-lg-flex justify-content-between">
                      <div className="">
                        <h3>{graphDataObj.x_axis.data_point_type==="weekly"?StartDurationWeekly:StartDurationMonthly}
                         - {graphDataObj.x_axis.data_point_type==="weekly"?EndDurationWeekly:EndDurationMonthly}
                        </h3>
                        <h5>{graphDataObj.y_axis.listing_type} - {humanize(graphDataObj.y_axis.data_value_type)}</h5>
                      </div>
                      <div className="d-lg-inline d-md-inline d-none">
                        <button
                          className="btn-download"
                          onClick={() => setdownloadModal(true)}
                        >
                          Download{" "}
                          <DownloadOutlined style={{ fontSize: "15px" }} />
                        </button>
                      </div>
                    </div>
                  </div>
                  {chartType === true ? (
                    <div className="chart-container">
                      <Bar
                        data={BarChart}
                        options={{
                          scales: {
                            x: {
                              beginAtZero: true,
                              grid: {
                                display: false,
                              },
                            },
                          },
                          maintainAspectRatio: false,

                          title: {
                            display: true,
                            text: "Average Salse per month",
                            fontSize: 20,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="chart-container">
                      <Line
                        data={LineChart}
                        // height={"100px"}
                        options={{
                          elements: {
                            point: {
                              radius: 7,
                            },
                          },
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              beginAtZero: true,
                              grid: {
                                display: false,
                              },
                            },
                          },

                          title: {
                            display: true,
                            text: "Average Salse per month",
                            fontSize: 2,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </div>
                  )}

</>
 ) : (
  <div className="example">
    <Spin className="spin" />
  </div>
)
}
                  <div className=" d-block mt-4 d-md-none d-lg-none text-center">
                    <button
                      className="btn-download"
                      onClick={() => setdownloadModal(true)}
                    >
                      Download <DownloadOutlined style={{ fontSize: "15px" }} />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-wrapper-blank text-center">
                  <div className="img-wrapper my-5">
                    <img src={graphIcon} className="m-auto" />
                  </div>
                  <div className="bottom-text text-secondary">
                    Welcome to Hamptons Market Data! Please select the options
                    from the left menu to view the Hamptons real estate market
                    trends and activity.
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
