import { configureStore } from '@reduxjs/toolkit'
import { graphReducer } from './Graph_slice/graph'
import {loginReducer} from './Login_slice/login'
import { locationReducer } from './market_location_slice/location'
import { pricePointReducer } from './markey_pricepoint_slice/pricepoint'
import { reportReducer } from './Reports_slice/reports'

export const store =configureStore({
    reducer:{
        Login:loginReducer,
        Location:locationReducer,
        PricePoint:pricePointReducer,
        Graph:graphReducer,
        Report:reportReducer
    }
})