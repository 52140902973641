import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {ApiCall} from '../../utils/api'


const initialState = {
    locationData:[],
    loading:false
  }

  export const getlocationData = createAsyncThunk(
    
    'HMD/getlocationData',
    
    async (body,thunkAPI) => {
    
    const response=await ApiCall("GET",body,"/api/location/list","").then((res)=>res)
    // console.log('response', response)
    
    return response.data.data
  })

  export const locationSlice=createSlice({
      name:"HMD",
      initialState,
      reducers:{
  
      },
      extraReducers:{
        [getlocationData.pending]: (state) => {
            state.loading = true
          },
          [getlocationData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.locationData = payload
          },
          [getlocationData.rejected]: (state, {payload}) => {
            state.loading = false
          },
      }
  })

  export const locationReducer=locationSlice.reducer