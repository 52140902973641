import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {ApiCall} from '../../utils/api'


const initialState = {
    graphData:[],
    loading:false
  }

  export const getGraph = createAsyncThunk(
    
    'HMD/getGraph',
    
    async (body,thunkAPI) => {
    console.log('body', body)
    
    const response=await ApiCall("POST",body,"/api/property/graph","").then((res)=>res)
    console.log('response', response)
    const {data}=response?.data
    data.reverse()

    
    return data
  })

  export const graphSlice=createSlice({
      name:"HMD",
      initialState,
      reducers:{
  
      },
      extraReducers:{
        [getGraph.pending]: (state) => {
            state.loading = true
            state.graphData = []
          },
          [getGraph.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.graphData = payload
          },
          [getGraph.rejected]: (state, {payload}) => {
            state.loading = false
            state.graphData = []
          },
      }
  })

  export const graphReducer=graphSlice.reducer